import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import defaultLogo from "assets/img/sdg-bg.png";
import ActionMenu from "./ActionMenu.jsx";
import { MEDIA_ROOT } from "config.js";
import {SDGicons} from "../../views/Projects/ProjectDetails";

const useStyles = makeStyles({
  tableContainer: {
    overflow: "auto",
  },
  table: {
    minWidth: 650,
  },
  image: {
    width: "50px",
    height: "50px",
    borderRadius: "8px",
    objectFit: "contain",
  },
  sdgWrapper: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap:'10px'
  },
});

const InstituteSummaryTable = ({
  data,
  history,
  followInstitute,
  unfollowInstitute,
}) => {
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderByColumn, setOrderByColumn] = useState("name");
  const sortedData = [...data].sort((a, b) => {
    if (order === "asc") {
      return a[orderByColumn] > b[orderByColumn] ? 1 : -1;
    } else {
      return a[orderByColumn] < b[orderByColumn] ? 1 : -1;
    }
  });

  const handleSortRequest = (column) => {
    setOrder(
      column === orderByColumn ? (order === "asc" ? "desc" : "asc") : "asc",
    );
    setOrderByColumn(column);
  };

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sortDirection="asc" align="left">
              Image
            </TableCell>
            <TableCell align="left">SDGs</TableCell>
            <TableCell sortDirection="asc" align="left">
              <TableSortLabel
                active={orderByColumn === "name"}
                direction={orderByColumn === "name" ? order : "asc"}
                onClick={() => handleSortRequest("name")}
              >
                Name
              </TableSortLabel>
            </TableCell>
            <TableCell align="left">Type</TableCell>
            <TableCell align="left">Mission</TableCell>
            <TableCell align="left">Address</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.length ? (
            sortedData.map(
              (
                {
                  name,
                  type,
                  mission,
                  avatar,
                  id,
                  is_following,
                  address,
                  website_url,
                  sustainable_development_goals,
                },
                idx,
              ) => {
                const imgUrl = avatar ? `${MEDIA_ROOT}${avatar}` : defaultLogo;
                return (
                  <TableRow key={id}>
                    <TableCell align="left" component="th" scope="row">
                      <img className={classes.image} src={imgUrl} alt={name} />
                    </TableCell>
                    <TableCell align="left">
                      <div className={classes.sdgWrapper}>
                        {sustainable_development_goals.map((sdg) => (
                          <img
                            className={classes.image}
                            src={SDGicons[sdg]}
                            alt={name}
                          />
                        ))}
                      </div>
                    </TableCell>
                    <TableCell align="left">{name}</TableCell>
                    <TableCell align="left">{type}</TableCell>
                    <TableCell align="left">{mission}</TableCell>
                    <TableCell align="left">
                      {address
                        ? `${address.city_name} ${address.country_name}`
                        : "-"}
                    </TableCell>
                    <TableCell align="right">
                      <ActionMenu
                        id={id}
                        idx={idx}
                        websiteUrl={website_url}
                        history={history}
                        followInstitute={followInstitute}
                        unfollowInstitute={unfollowInstitute}
                        isFollowing={is_following}
                      />
                    </TableCell>
                  </TableRow>
                );
              },
            )
          ) : (
            <h6>No Institute Data</h6>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InstituteSummaryTable;
