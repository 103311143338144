export const searchOptions = [
  { value: "institute", label: "Institute" },
  { value: "projects", label: "Projects" },
  //{ key: "events", label: "Events" },
  { value: "members", label: "Members" }
];

export const projectTypes = [
  {
    text: "Investment Portfolio",
    value: "Investment Portfolio",
    color: "#34495E",
    description: "" //add description
  },
  {
    text: "Data Collection",
    value: "Data Collection",
    color: "#34495E",
    description: "" //add description
  },
  {
    text: "Rating",
    value: "Rating",
    color: "#34495E",
    description: "" //add description
  },
];

export const instituteTypes = [
  {
    text: "Financial Institution",
    value: "Financial Institution",
    color: "#E74C3C",
    description:
      "Microfinance, cooperatives, NGOs operating in developing countries."
  },
  {
    text: "Investor/Asset manager",
    value: "Investor/Asset manager",
    color: "#34495E",
    description:
      "Large banks, funding agencies, operating (mainly) in western countries."
  },
  {
    text: "Technology/Service Provider",
    value: "Technology/Service Provider",
    color: "#138D75",
    description: "Companies (SMEs) providing (e.g.) energy technologies."
  },
  {
    text: "NGO",
    value: "NGO",
    color: "#A569BD",
    description:
      "Non-governamental organizations, networks, international initiatives."
  },
  // {
  //   text: "Governamental agencies",
  //   value: "Governamental agencies",
  //   description: ""
  // },
  {
    text: "Technical assistance providers",
    value: "Technical assistance providers",
    color: "#85C1E9 ",
    description:
      "Consulting companies, Multilateral organizations, Rating Agencies"
  },
  {
    text: "Academia",
    value: "Academia",
    color: "#F8C471",
    description: "Members of Universities or research institutes"
  }
];

export const INSTITUTE_LEGAL_STATUS_CHOICES = {
  FinancialInstitute: [
    { text: "Bank", value: "Bank" },
    { text: "Credit Union/Cooperative", value: "Credit Union/Cooperative" },
    { text: "NBFI", value: "NBFI" },
    { text: "NGO", value: "NGO" },
    { text: "Rural Bank", value: "Rural Bank" },
    { text: "SACCO", value: "SACCO" },
    { text: "Non-financial Bank", value: "Non-financial Bank" }
  ],

  Investor: [
    {
      text: "Development Financial Institution",
      value: "Development Financial Institution"
    },
    {
      text: "Financial Institution - Commercial Bank",
      value: "Financial Institution - Commercial Bank"
    },
    {
      text: "Financial Institution - Cooperative",
      value: "Financial Institution - Cooperative"
    },
    {
      text: "Financial Institution - Public Bank",
      value: "Financial Institution - Public Bank"
    },
    {
      text: "Fund - Commercial Fixed Income",
      value: "Fund - Commercial Fixed Income"
    },
    { text: "Fund - Blended Value", value: "Fund - Blended Value" },
    { text: "Fund - Holding Company", value: "Fund - Holding Company" },
    { text: "Fund - NGO", value: "Fund - NGO" },
    { text: "Fund - Mutual Fund", value: "Fund - Mutual Fund" },
    { text: "Fund - Private Equity", value: "Fund - Private Equity" },
    {
      text: "Fund - Structured MF Vehicle",
      value: "Fund - Structured MF Vehicle"
    },
    { text: "Corporation", value: "Corporation" },
    { text: "Individual", value: "Individual" },
    { text: "Peer-to-peer micro lending", value: "Peer-to-peer micro lending" }
  ],

  TechnologyProvider: [
    { text: "Government", value: "Government" },
    { text: "Private", value: "Private" },
    { text: "NGO", value: "NGO" },
    { text: "Cooperative", value: "Cooperative" }
  ],

  NGO: [
    { text: "Platform/Association", value: "Platform/Association" },
    {
      text: "Regional or National Network - MFI",
      value: "Regional or National Network - MFI"
    },
    {
      text: "Regional or National Network - Investors",
      value: "Regional or National Network - Investors"
    },
    {
      text: "International Network - MFI",
      value: "International Network - MFI"
    },
    {
      text: "International Network - Investors",
      value: "International Network - Investors"
    },
    { text: "Foundation", value: "Foundation" },
    { text: "Other", value: "Other" }
  ],

  Governamental: [
    { text: "Bi/Multilateral Aid Agency", value: "Bi/Multilateral Aid Agency" },
    { text: "Development Program", value: "Development Program" },
    { text: "Central Bank", value: "Central Bank" },
    {
      text: "Department, Ministry, Agency",
      value: "Department, Ministry, Agency"
    }
  ],
  TechnicalAssistance: [
    { text: "Consultant company", value: "Consultant company" },
    { text: "Software/FinTech", value: "Software/FinTech" },
    { text: "Individual", value: "Individual" },
    {
      text: "Technical Assistance branch from a Fund/Investor",
      value: "Technical Assistance branch from a Fund/Investor"
    },
    { text: "Multilateral organization", value: "Multilateral organization" },
    { text: "Rating agency", value: "Rating agency" },
    { text: "Consortium", value: "Consortium" }
  ],
  Academia: [
    { text: "University", value: "University" },
    { text: "Research institute", value: "Research institute" },
    { text: "R&D of NGO", value: "R&D of NGO" },
    { text: "R&D of private company", value: "R&D of private company" }
  ]
};

export const years = [...Array(40).keys()].map(key => {
  return {
    text: `2019-years${key}`,
    value: `2019-years${key + 1}`
  };
});

export const SDGs = [
  {
    text: "SDG1: No Poverty",
    value: "SDG1",
    external_url: "https://sustainabledevelopment.un.org/sdg1",
    color: "#E43232"
  },
  {
    text: "SDG2: Zero Hunger",
    value: "SDG2",
    external_url: "https://sustainabledevelopment.un.org/sdg2",
    color: "#CD9A31"
  },
  {
    text: "SDG3: Good Health and Well-being",
    value: "SDG3",
    external_url: "https://sustainabledevelopment.un.org/sdg3",
    color: "#3F8E3B"
  },
  {
    text: "SDG4: Quality Education",
    value: "SDG4",
    external_url: "https://sustainabledevelopment.un.org/sdg4",
    color: "#B93135"
  },
  {
    text: "SDG5: Gender Equality",
    value: "SDG5",
    external_url: "https://sustainabledevelopment.un.org/sdg5",
    color: "#E74431"
  },
  {
    text: "SDG6: Clean Water and Sanitation",
    value: "SDG6",
    external_url: "https://sustainabledevelopment.un.org/sdg6",
    color: "#14A3C3"
  },
  {
    text: "SDG7: Affordable and Clean Energy",
    value: "SDG7",
    external_url: "https://sustainabledevelopment.un.org/sdg7",
    color: "#F8B028"
  },
  {
    text: "SDG8: Decent Work and Economic Growth",
    value: "SDG8",
    external_url: "https://sustainabledevelopment.un.org/sdg8",
    color: "#862C38"
  },
  {
    text: "SDG9: Industry, Innovation, and Infrastructure",
    value: "SDG9",
    external_url: "https://sustainabledevelopment.un.org/sdg9",
    color: "#EC6C2F"
  },
  {
    text: "SDG10: Reducing Inequality",
    value: "SDG10",
    external_url: "https://sustainabledevelopment.un.org/sdg10",
    color: "#DB2F7B"
  },
  {
    text: "SDG11: Sustainable Cities and Communities",
    value: "SDG11",
    external_url: "https://sustainabledevelopment.un.org/sdg11",
    color: "#F49632"
  },
  {
    text: "SDG12: Responsible Consumption and Production",
    value: "SDG12",
    external_url: "https://sustainabledevelopment.un.org/sdg12",
    color: "#C98530"
  },
  {
    text: "SDG13: Climate Action",
    value: "SDG13",
    external_url: "https://sustainabledevelopment.un.org/sdg13",
    color: "#506D37"
  },
  {
    text: "SDG14: Life Below Water",
    value: "SDG14",
    external_url: "https://sustainabledevelopment.un.org/sdg14",
    color: "#1071A4"
  },
  {
    text: "SDG15: Life On Land",
    value: "SDG15",
    external_url: "https://sustainabledevelopment.un.org/sdg15",
    color: "#65AF3C"
  },
  {
    text: "SDG16: Peace, Justice, and Strong Institutions",
    value: "SDG16",
    external_url: "https://sustainabledevelopment.un.org/sdg16",
    color: "#2C4F75"
  },
  {
    text: "SDG17: Partnerships for the Goals",
    value: "SDG17",
    external_url: "https://sustainabledevelopment.un.org/sdg17",
    color: "#333453"
  }
];

export const userRoles = [
  { text: "CEO", value: "CEO" },
  { text: "CTO", value: "CTO" },
  { text: "CFO", value: "CFO" },
  { text: "Assistant", value: "Assistant" },
  { text: "Company Secretary", value: "Company Secretary" },
  { text: "Head of Research", value: "Head of Research" },
  { text: "Head of Sales", value: "Head of Sales" },
  { text: "Researcher", value: "Researcher" },
  { text: "Chairman", value: "Chairman" },
  { text: "Professor", value: "Professor" },
  { text: "Other", value: "Other" },
  { text: "Communications manager", value: "Communications manager" },
  { text: "Trustee", value: "Trustee" },
  { text: "Sales & Marketing", value: "Sales & Marketing" },
  { text: "Investment Analyst", value: "Investment Analyst" },
  {
    text: "Investment Analyst - Renewable Energies & EE",
    value: "Investment Analyst - Renewable Energies & EE"
  },
  { text: "Investment Analyst - Water", value: "Investment Analyst - Water" },
  {
    text: "Investment Analyst - Housing",
    value: "Investment Analyst - Housing"
  },
  {
    text: "Investment Analyst - CorpGov",
    value: "Investment Analyst - CorpGov"
  },
  {
    text: "Investment Analyst - Financial",
    value: "Investment Analyst - Financial"
  },
  { text: "Investment Banker", value: "Investment Banker" },
  { text: "Investment Consultant", value: "Investment Consultant" },
  {
    text: "Investment Consultant - Institutional",
    value: "Investment Consultant - Institutional"
  },
  { text: "Investors Relations Manager", value: "Investors Relations Manager" },
  {
    text: "Policy or management Analyst",
    value: "Policy or management Analyst"
  },
  { text: "Portfolio Manager", value: "Portfolio Manager" },
  {
    text: "Portfolio Manager - Renewable Energy & EE",
    value: "Portfolio Manager - Renewable Energy & EE"
  },
  {
    text: "Portfolio Manager - Agriculture",
    value: "Portfolio Manager - Agriculture"
  },
  {
    text: "Portfolio Manager - Education",
    value: "Portfolio Manager - Education"
  },
  {
    text: "Portfolio Manager - Mainstream financial",
    value: "Portfolio Manager - Mainstream financial"
  }
];

export const worldCountries = {
  "Central Asia and Caucausus": [
    { text: "AFGHANISTAN", value: "AFGHANISTAN" },
    { text: "ARMENIA", value: "ARMENIA" },
    { text: "AZERBAIJAN", value: "AZERBAIJAN" },
    { text: "GEORGIA", value: "GEORGIA" },
    { text: "KAZAKHSTAN", value: "KAZAKHSTAN" },
    { text: "KYRGYZSTAN", value: "KYRGYZSTAN" },
    { text: "TAJIKISTAN", value: "TAJIKISTAN" },
    { text: "TURKMENISTAN", value: "TURKMENISTAN" },
    { text: "UZBEKISTAN", value: "UZBEKISTAN" }
  ],
  Europe: [
    { text: "ALBANIA", value: "ALBANIA" },
    { text: "ANDORRA", value: "ANDORRA" },
    { text: "AUSTRIA", value: "AUSTRIA" },
    { text: "BELARUS", value: "BELARUS" },
    { text: "BELGIUM", value: "BELGIUM" },
    { text: "BOSNIA AND HERZEGOVINA", value: "BOSNIA AND HERZEGOVINA" },
    { text: "BULGARIA", value: "BULGARIA" },
    { text: "CROATIA", value: "CROATIA" },
    { text: "CYPRUS", value: "CYPRUS" },
    { text: "CZECH REPUBLIC", value: "CZECH REPUBLIC" },
    { text: "DENMARK", value: "DENMARK" },
    { text: "ESTONIA", value: "ESTONIA" },
    { text: "FAROE ISLANDS", value: "FAROE ISLANDS" },
    { text: "FINLAND", value: "FINLAND" },
    { text: "FRANCE", value: "FRANCE" },
    { text: "GERMANY", value: "GERMANY" },
    { text: "GIBRALTAR", value: "GIBRALTAR" },
    { text: "GREECE", value: "GREECE" },
    { text: "HUNGARY", value: "HUNGARY" },
    { text: "ICELAND", value: "ICELAND" },
    { text: "IRELAND", value: "IRELAND" },
    { text: "ITALY", value: "ITALY" },
    { text: "KOSOVO", value: "KOSOVO" },
    { text: "LATVIA", value: "LATVIA" },
    { text: "LIECHTENSTEIN", value: "LIECHTENSTEIN" },
    { text: "LITHUANIA", value: "LITHUANIA" },
    { text: "LUXEMBOURG", value: "LUXEMBOURG" },
    {
      text: "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF",
      value: "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF"
    },
    { text: "MALTA", value: "MALTA" },
    { text: "MOLDOVA, REPUBLIC OF", value: "MOLDOVA, REPUBLIC OF" },
    { text: "MONACO", value: "MONACO" },
    { text: "NETHERLANDS", value: "NETHERLANDS" },
    { text: "NORWAY", value: "NORWAY" },
    { text: "POLAND", value: "POLAND" },
    { text: "PORTUGAL", value: "PORTUGAL" },
    { text: "ROMANIA", value: "ROMANIA" },
    { text: "RUSSIAN FEDERATION", value: "RUSSIAN FEDERATION" },
    { text: "SAN MARINO", value: "SAN MARINO" },
    { text: "SERBIA AND MONTENEGRO", value: "SERBIA AND MONTENEGRO" },
    { text: "SLOVAKIA", value: "SLOVAKIA" },
    { text: "SLOVENIA", value: "SLOVENIA" },
    { text: "SPAIN", value: "SPAIN" },
    { text: "SVALBARD AND JAN MAYEN", value: "SVALBARD AND JAN MAYEN" },
    { text: "SWEDEN", value: "SWEDEN" },
    { text: "SWITZERLAND", value: "SWITZERLAND" },
    { text: "TURKEY", value: "TURKEY" },
    { text: "UKRAINE", value: "UKRAINE" },
    { text: "UNITED KINGDOM", value: "UNITED KINGDOM" }
  ],
  "Northern Africa": [
    { text: "ALGERIA", value: "ALGERIA" },
    { text: "EGYPT", value: "EGYPT" },
    { text: "LIBYAN ARAB JAMAHIRIYA", value: "LIBYAN ARAB JAMAHIRIYA" },
    { text: "MOROCCO", value: "MOROCCO" },
    { text: "SUDAN", value: "SUDAN" },
    { text: "TUNISIA", value: "TUNISIA" }
  ],
  Oceania: [
    { text: "AMERICAN SAMOA", value: "AMERICAN SAMOA" },
    { text: "AUSTRALIA", value: "AUSTRALIA" },
    { text: "CHRISTMAS ISLAND", value: "CHRISTMAS ISLAND" },
    { text: "COCOS (KEELING) ISLANDS", value: "COCOS (KEELING) ISLANDS" },
    { text: "COOK ISLANDS", value: "COOK ISLANDS" },
    { text: "FIJI", value: "FIJI" },
    { text: "FRENCH POLYNESIA", value: "FRENCH POLYNESIA" },
    { text: "GUAM", value: "GUAM" },
    { text: "KIRIBATI", value: "KIRIBATI" },
    { text: "MARSHALL ISLANDS", value: "MARSHALL ISLANDS" },
    {
      text: "MICRONESIA, FEDERATED STATES OF",
      value: "MICRONESIA, FEDERATED STATES OF"
    },
    { text: "NEW CALEDONIA", value: "NEW CALEDONIA" },
    { text: "NEW ZEALAND", value: "NEW ZEALAND" },
    { text: "NIUE", value: "NIUE" },
    { text: "NORFOLK ISLAND", value: "NORFOLK ISLAND" },
    { text: "PAPUA NEW GUINEA", value: "PAPUA NEW GUINEA" },
    { text: "SAMOA", value: "SAMOA" },
    { text: "SOLOMON ISLANDS", value: "SOLOMON ISLANDS" },
    { text: "TOKELAU", value: "TOKELAU" },
    { text: "TONGA", value: "TONGA" },
    { text: "TUVALU", value: "TUVALU" },
    { text: "VANUATU", value: "VANUATU" }
  ],
  "Middle Africa": [
    { text: "ANGOLA", value: "ANGOLA" },
    { text: "CAMEROON", value: "CAMEROON" },
    { text: "CENTRAL AFRICAN REPUBLIC", value: "CENTRAL AFRICAN REPUBLIC" },
    { text: "CHAD", value: "CHAD" },
    { text: "CONGO", value: "CONGO" },
    {
      text: "CONGO, THE DEMOCRATIC REPUBLIC OF",
      value: "CONGO, THE DEMOCRATIC REPUBLIC OF"
    },
    { text: "EQUATORIAL GUINEA", value: "EQUATORIAL GUINEA" },
    { text: "GABON", value: "GABON" },
    { text: "SAO TOME AND PRINCIPE", value: "SAO TOME AND PRINCIPE" }
  ],
  "The Caribbean": [
    { text: "ANGUILLA", value: "ANGUILLA" },
    { text: "ANTIGUA AND BARBUDA", value: "ANTIGUA AND BARBUDA" },
    { text: "ARUBA", value: "ARUBA" },
    { text: "BAHAMAS", value: "BAHAMAS" },
    { text: "BARBADOS", value: "BARBADOS" },
    { text: "CAYMAN ISLANDS", value: "CAYMAN ISLANDS" },
    { text: "CUBA", value: "CUBA" },
    { text: "DOMINICA", value: "DOMINICA" },
    { text: "DOMINICAN REPUBLIC", value: "DOMINICAN REPUBLIC" },
    { text: "GRENADA", value: "GRENADA" },
    { text: "GUADELOUPE", value: "GUADELOUPE" },
    { text: "HAITI", value: "HAITI" },
    {
      text: "HEARD ISLAND AND MCDONALD ISLANDS",
      value: "HEARD ISLAND AND MCDONALD ISLANDS"
    },
    { text: "JAMAICA", value: "JAMAICA" },
    { text: "MARTINIQUE", value: "MARTINIQUE" },
    { text: "MONTSERRAT", value: "MONTSERRAT" },
    { text: "NETHERLANDS ANTILLES", value: "NETHERLANDS ANTILLES" },
    { text: "PUERTO RICO", value: "PUERTO RICO" },
    { text: "SAINT KITTS AND NEVIS", value: "SAINT KITTS AND NEVIS" },
    { text: "SAINT LUCIA", value: "SAINT LUCIA" },
    {
      text: "SAINT VINCENT AND THE GRENADINES",
      value: "SAINT VINCENT AND THE GRENADINES"
    },
    {
      text: "VC - SAINT VINCENT AND THE GRENADINES",
      value: "VC - SAINT VINCENT AND THE GRENADINES"
    },
    { text: "SG - SINGAPORE", value: "SG - SINGAPORE" },
    { text: "TRINIDAD AND TOBAGO", value: "TRINIDAD AND TOBAGO" },
    { text: "TURKS AND CAICOS ISLANDS", value: "TURKS AND CAICOS ISLANDS" },
    { text: "BRITISH VIRGIN ISLANDS", value: "BRITISH VIRGIN ISLANDS" },
    { text: "U.S. VIRGIN ISLANDS", value: "U.S. VIRGIN ISLANDS" }
  ],
  "South America": [
    { text: "ARGENTINA", value: "ARGENTINA" },
    { text: "BOLIVIA", value: "BOLIVIA" },
    { text: "BRAZIL", value: "BRAZIL" },
    { text: "CHILE", value: "CHILE" },
    { text: "COLOMBIA", value: "COLOMBIA" },
    { text: "ECUADOR", value: "ECUADOR" },
    {
      text: "FALKLAND ISLANDS (MALVINAS)",
      value: "FALKLAND ISLANDS (MALVINAS)"
    },
    { text: "FRENCH GUIANA", value: "FRENCH GUIANA" },
    { text: "PARAGUAY", value: "PARAGUAY" },
    { text: "PERU", value: "PERU" },
    { text: "SURINAME", value: "SURINAME" },
    { text: "URUGUAY", value: "URUGUAY" },
    { text: "VENEZUELA", value: "VENEZUELA" }
  ],
  "Middle East": [
    { text: "BAHRAIN", value: "BAHRAIN" },
    { text: "IRAN, ISLAMIC REPUBLIC OF", value: "IRAN, ISLAMIC REPUBLIC OF" },
    { text: "IRAQ", value: "IRAQ" },
    { text: "ISRAEL", value: "ISRAEL" },
    { text: "JORDAN", value: "JORDAN" },
    { text: "KUWAIT", value: "KUWAIT" },
    { text: "LEBANON", value: "LEBANON" },
    { text: "OMAN", value: "OMAN" },
    {
      text: "PALESTINIAN TERRITORY, OCCUPIED",
      value: "PALESTINIAN TERRITORY, OCCUPIED"
    },
    { text: "QATAR", value: "QATAR" },
    { text: "SAUDI ARABIA", value: "SAUDI ARABIA" },
    { text: "SYRIAN ARAB REPUBLIC", value: "SYRIAN ARAB REPUBLIC" },
    { text: "UNITED ARAB EMIRATES", value: "UNITED ARAB EMIRATES" },
    { text: "YEMEN", value: "YEMEN" }
  ],
  "Indian Subcontinent": [
    { text: "BANGLADESH", value: "BANGLADESH" },
    { text: "BHUTAN", value: "BHUTAN" },
    { text: "INDIA", value: "INDIA" },
    { text: "MALDIVES", value: "MALDIVES" },
    { text: "NEPAL", value: "NEPAL" },
    { text: "PAKISTAN", value: "PAKISTAN" },
    { text: "SRI LANKA", value: "SRI LANKA" }
  ],
  "Central America": [
    { text: "BELIZE", value: "BELIZE" },
    { text: "COSTA RICA", value: "COSTA RICA" },
    { text: "EL SALVADOR", value: "EL SALVADOR" },
    { text: "GUATEMALA", value: "GUATEMALA" },
    { text: "HONDURAS", value: "HONDURAS" },
    { text: "MEXICO", value: "MEXICO" },
    { text: "NICARAGUA", value: "NICARAGUA" },
    { text: "PANAMA", value: "PANAMA" }
  ],
  "Western Africa": [
    { text: "BENIN", value: "BENIN" },
    { text: "BURKINA FASO", value: "BURKINA FASO" },
    { text: "CAPE VERDE", value: "CAPE VERDE" },
    { text: "CÔTE D'IVOIRE", value: "CÔTE D'IVOIRE" },
    { text: "WESTERN SAHARA", value: "WESTERN SAHARA" },
    { text: "GAMBIA", value: "GAMBIA" },
    { text: "GHANA", value: "GHANA" },
    { text: "GUINEA", value: "GUINEA" },
    { text: "GUINEA-BISSAU", value: "GUINEA-BISSAU" },
    { text: "GUYANA", value: "GUYANA" },
    { text: "LIBERIA", value: "LIBERIA" },
    { text: "MALI", value: "MALI" },
    { text: "MAURITANIA", value: "MAURITANIA" },
    { text: "NIGER", value: "NIGER" },
    { text: "NIGERIA", value: "NIGERIA" },
    { text: "SAINT HELENA", value: "SAINT HELENA" },
    { text: "SENEGAL", value: "SENEGAL" },
    { text: "SIERRA LEONE", value: "SIERRA LEONE" },
    { text: "TOGO", value: "TOGO" }
  ],
  "Northern America": [
    { text: "BERMUDA", value: "BERMUDA" },
    { text: "CANADA", value: "CANADA" },
    { text: "GREENLAND", value: "GREENLAND" },
    { text: "SAINT PIERRE AND MIQUELON", value: "SAINT PIERRE AND MIQUELON" },
    { text: "UNITED STATES", value: "UNITED STATES" }
  ],
  "Southern Africa": [
    { text: "BOTSWANA", value: "BOTSWANA" },
    { text: "BOUVET ISLAND", value: "BOUVET ISLAND" },
    { text: "LESOTHO", value: "LESOTHO" },
    { text: "NAURU", value: "NAURU" },
    { text: "NAMIBIA", value: "NAMIBIA" },
    { text: "SOUTH AFRICA", value: "SOUTH AFRICA" },
    { text: "SWAZILAND", value: "SWAZILAND" }
  ],
  "Asia Pacific": [
    {
      text: "BRITISH INDIAN OCEAN TERRITORY",
      value: "BRITISH INDIAN OCEAN TERRITORY"
    },
    { text: "PEOPLE'S REPUBLIC OF CHINA", value: "PEOPLE'S REPUBLIC OF CHINA" },
    {
      text: "FRENCH SOUTHERN TERRITORIES",
      value: "FRENCH SOUTHERN TERRITORIES"
    },
    { text: "HONG KONG", value: "HONG KONG" },
    { text: "JAPAN", value: "JAPAN" },
    {
      text: "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF",
      value: "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF"
    },
    { text: "KOREA, REPUBLIC OF", value: "KOREA, REPUBLIC OF" },
    { text: "MACAO", value: "MACAO" },
    { text: "MONGOLIA", value: "MONGOLIA" },
    { text: "NORTHERN MARIANA ISLANDS", value: "NORTHERN MARIANA ISLANDS" },
    { text: "PALAU", value: "PALAU" },
    { text: "TAIWAN, REPUBLIC OF CHINA", value: "TAIWAN, REPUBLIC OF CHINA" }
  ],
  "South-Eastern Asia": [
    { text: "BRUNEI DARUSSALAM", value: "BRUNEI DARUSSALAM" },
    { text: "CAMBODIA", value: "CAMBODIA" },
    { text: "INDONESIA", value: "INDONESIA" },
    {
      text: "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
      value: "LAO PEOPLE'S DEMOCRATIC REPUBLIC"
    },
    { text: "MALAYSIA", value: "MALAYSIA" },
    { text: "MYANMAR", value: "MYANMAR" },
    { text: "PHILIPPINES", value: "PHILIPPINES" },
    { text: "SINGAPORE", value: "SINGAPORE" },
    { text: "THAILAND", value: "THAILAND" },
    { text: "TIMOR-LESTE", value: "TIMOR-LESTE" },
    { text: "VIET NAM", value: "VIET NAM" }
  ],
  "Eastern Africa": [
    { text: "BURUNDI", value: "BURUNDI" },
    { text: "COMOROS", value: "COMOROS" },
    { text: "DJIBOUTI", value: "DJIBOUTI" },
    { text: "ERITREA", value: "ERITREA" },
    { text: "ETHIOPIA", value: "ETHIOPIA" },
    { text: "KENYA", value: "KENYA" },
    { text: "MADAGASCAR", value: "MADAGASCAR" },
    { text: "MALAWI", value: "MALAWI" },
    { text: "MAURITIUS", value: "MAURITIUS" },
    { text: "MAYOTTE", value: "MAYOTTE" },
    { text: "MOZAMBIQUE", value: "MOZAMBIQUE" },
    { text: "RÉUNION", value: "RÉUNION" },
    { text: "RWANDA", value: "RWANDA" },
    { text: "SEYCHELLES", value: "SEYCHELLES" },
    { text: "SOMALIA", value: "SOMALIA" },
    {
      text: "TANZANIA, UNITED REPUBLIC OF",
      value: "TANZANIA, UNITED REPUBLIC OF"
    },
    { text: "UGANDA", value: "UGANDA" },
    { text: "ZAMBIA", value: "ZAMBIA" },
    { text: "ZIMBABWE", value: "ZIMBABWE" }
  ]
};

export const WORLD_REGION_CHOICES = [
  { text: "Eastern Africa", value: "Eastern Africa" },
  { text: "Western Africa", value: "Western Africa" },
  { text: "Middle Africa", value: "Middle Africa" },
  { text: "Northern Africa", value: "Northern Africa" },
  { text: "Southern Africa", value: "Southern Africa" },
  { text: "Central Asia and Caucausus", value: "Central Asia and Caucausus" },
  { text: "The Caribbean", value: "The Caribbean" },
  { text: "Central America", value: "Central America" },
  { text: "South America", value: "South America" },
  { text: "Northern America", value: "Northern America" },
  { text: "Middle East", value: "Middle East" },
  { text: "South-Eastern Asia", value: "South-Eastern Asia" },
  { text: "Asia Pacific", value: "Asia Pacific" },
  { text: "Indian Subcontinent", value: "Indian Subcontinent" },
  { text: "Europe", value: "Europe" }
];


export const regionShortCodeMap = {
  "Eastern Africa": "EA",
  "Western Africa": "WA",
  "Middle Africa": "MA",
  "Northern Africa": "NA",
  "Southern Africa": "SA",
  "Central Asia and Caucasus": "CAC",
  "The Caribbean": "TC",
  "Central America": "CA",
  "South America": "SA2",
  "Northern America": "NA2",
  "Middle East": "ME",
  "South-Eastern Asia": "SEA",
  "Asia Pacific": "AP",
  "Indian Subcontinent": "IS",
  "Europe": "EU"
};

export const FINANCIAL_SERVICE_CHOICES = [
  { text: "Loans - Short term", value: "Loans - Short term" },
  { text: "Loans - Long term", value: "Loans - Long term" },
  {
    text: "Loans - Individual Consumption",
    value: "Loans - Individual Consumption"
  },
  { text: "Loans - Individual Business", value: "Loans - Individual Business" },
  { text: "Loans - Group", value: "Loans - Group" },
  { text: "Loans - Emergency", value: "Loans - Emergency" },
  { text: "Loans - Agriculture", value: "Loans - Agriculture" },
  {
    text: "Loans - Renewable Energies & EE",
    value: "Loans - Renewable Energies & EE"
  },
  { text: "Loans - Education", value: "Loans - Education" },
  { text: "Loans - Housing", value: "Loans - Housing" },
  { text: "Loans - Water", value: "Loans - Water" },
  { text: "Loans - Sanitation", value: "Loans - Sanitation" },
  { text: "Loans - Waste Management", value: "Loans - Waste Management" },
  { text: "Loans - Health", value: "Loans - Health" },
  { text: "Fund transfers and cheques", value: "Fund transfers and cheques" },
  { text: "Voluntary savings", value: "Voluntary savings" },
  { text: "Microinsurance", value: "Microinsurance" },
  { text: "Remittances", value: "Remittances" },
  {
    text: "Pension plan - Long time deposits",
    value: "Pension plan - Long time deposits"
  },
  {
    text: "Business Development Services",
    value: "Business Development Services"
  },
  { text: "Training and consulting", value: "Training and consulting" }
];

export const FUNDING_INSTRUMENT_CHOICES = {
  FinancialInstitute: [
    { text: "Loans", value: "Loans" },
    { text: "Savings", value: "Savings" },
    { text: "Debt", value: "Debt" },
    { text: "Guarantees", value: "Guarantees" },
    { text: "Subsidies", value: "Subsidies" },
    { text: "Donations", value: "Donations" },
    { text: "Equity", value: "Equity" }
  ],
  Investor: [
    { text: "Funding Senior Debt", value: "Funding Senior Debt" },
    { text: "Funding Subordinated Debt", value: "Funding Subordinated Debt" },
    { text: "Funding Equity", value: "Funding Equity" },
    { text: "Subsidies", value: "Subsidies" },
    { text: "Donations", value: "Donations" },
    { text: "Guarantees", value: "Guarantees" },
    { text: "Capacity Building Grants", value: "Capacity Building Grants" },
    {
      text: "Technical Assistance Grants",
      value: "Technical Assistance Grants"
    }
  ]
};

export const FUNDING_CURRENCY_CHOICES = [
  { text: "US Dollars", value: "US Dollars" },
  { text: "Euro", value: "Euro" },
  { text: "Local Currency", value: "Local Currency" }
];

export const TA_SERVICE_CHOICES = [
  { text: "Rating", value: "Rating" },
  { text: "IT", value: "IT" },
  { text: "Law firm", value: "Law firm" },
  { text: "Audit", value: "Audit" }
];

export const BASIC_SERVICE_CHOICES = [
  { text: "Food security", value: "Food security" },
  { text: "Water", value: "Water" },
  { text: "Housing", value: "Housing" },
  { text: "Clothing", value: "Clothing" },
  { text: "Sanitation", value: "Sanitation" },
  { text: "Education", value: "Education" },
  { text: "Healthcare", value: "Healthcare" },
  { text: "Energy & Environment", value: "Energy & Environment" },
  { text: "Waste management", value: "Waste management" },
  { text: "Agriculture", value: "Agriculture" }
];

export const FOCUS_AREA_CHOICES = [
  { text: "Rural", value: "Rural" },
  { text: "Women", value: "Women" },
  { text: "Business entrepreneurship", value: "Business entrepreneurship" },
  { text: "Technical assistance", value: "Technical assistance" }
];

export const IMPACT_METRIC_CHOICES = [
  { text: "CERISE/ODD", value: "CERISE/ODD" },
  { text: "GOGLA", value: "GOGLA" },
  { text: "IRIS", value: "IRIS" },
  { text: "SPTF4", value: "SPTF4" },
  { text: "HEDERA", value: "HEDERA" }
];

export const COMPANY_SIZE_CHOICES = [
  { text: "<5 employees", value: "<5 employees" },
  { text: "5 to 20 employees", value: "5 to 20 employees" },
  { text: ">20 employees", value: ">20 employees" },
  { text: "21 - 50 employees", value: "21 - 50 employees" },
  { text: "51 - 100 employees", value: "51 - 100 employees" },
  { text: ">100 employees", value: ">100 employees" }
];

export const INSTITUTE_REGULATED_CHOICES = {
  FinancialInstitute: [
    { text: "Yes", value: "Yes" },
    {
      text: "On-going regulation process",
      value: "On-going regulation process"
    },
    { text: "No", value: "No" }
  ]
};

export const FUNDED_INSTITUTION_CHOICES = [
  { text: "Banks", value: "Banks" },
  { text: "Development Banks", value: "Development Banks" },
  { text: "Impact Funds", value: "Impact Funds" },
  { text: "MFI - Tier 1", value: "MFI - Tier 1" },
  { text: "MFI - Tier 2", value: "MFI - Tier 2" },
  { text: "MFI - Tier 3", value: "MFI - Tier 3" },
  { text: "Microfinance Funds", value: "Microfinance Funds" },
  { text: "Others", value: "Others" },
  { text: "SMEs", value: "SMEs" }
];

export const PORTFOLIO_SIZE_CHOICES = [
  { text: "Less than 1 Million USD", value: "Less than 1 Million USD" },
  { text: "1 - 5 Millions USD", value: "1 - 5 Millions USD" },
  { text: "More than 5 Millions USD", value: "More than 5 Millions USD" }
];

export const YES_NO_CHOICES = [
  { text: "Yes", value: "Yes" },
  { text: "No", value: "No" }
];

export const TRUE_FALSE_CHOICES = [
  { text: "Yes", value: true },
  { text: "No", value: false }
];

export const PROFILE_VISIBILITY_CHOICES = {
  'public': 'Public','private':'Private'
}
