/* eslint-disable react/prop-types */
import React, { Component } from "react";

import Select from "react-select";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Modal from "@material-ui/core/Modal";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { ContactMail, Subject } from "@material-ui/icons";
import ReactHtmlParser from 'react-html-parser';
import 'react-circular-progressbar/dist/styles.css';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Person from "@material-ui/icons/PersonOutlineOutlined";
import People from "@material-ui/icons/PeopleOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Accordion from "components/Accordion/Accordion.js";
import NavPills from "components/NavPills/NavPills.js";
import Comment from "components/Comment/Comment.jsx";
import Info from "components/Typography/Info.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import ParticipantsList from "./ParticipantsList";
import Warning from "components/Typography/Warning.jsx";
import ProjectSettings from "./ProjectSettings";
import DeleteIcon from "@material-ui/icons/Delete";
import FillIcon from "@material-ui/icons/Edit";
import SettingsIcon from "@material-ui/icons/Settings";
import CustomSelectClassic from "components/CustomSelectClassic/CustomSelect.jsx";
import CustomSwitcher from "components/CustomSwitcher/CustomSwitcher";
import CustomTextArea from "components/CustomTextArea/CustomTextArea";

// sdg icons
import sdg1 from "assets/img/SDGs/E_SDG_Icons-01.jpg";
import sdg2 from "assets/img/SDGs/E_SDG_Icons-02.jpg";
import sdg3 from "assets/img/SDGs/E_SDG_Icons-03.jpg";
import sdg4 from "assets/img/SDGs/E_SDG_Icons-04.jpg";
import sdg5 from "assets/img/SDGs/E_SDG_Icons-05.jpg";
import sdg6 from "assets/img/SDGs/E_SDG_Icons-06.jpg";
import sdg7 from "assets/img/SDGs/E_SDG_Icons-07.jpg";
import sdg8 from "assets/img/SDGs/E_SDG_Icons-08.jpg";
import sdg9 from "assets/img/SDGs/E_SDG_Icons-09.jpg";
import sdg10 from "assets/img/SDGs/E_SDG_Icons-10.jpg";
import sdg11 from "assets/img/SDGs/E_SDG_Icons-11.jpg";
import sdg12 from "assets/img/SDGs/E_SDG_Icons-12.jpg";
import sdg13 from "assets/img/SDGs/E_SDG_Icons-13.jpg";
import sdg14 from "assets/img/SDGs/E_SDG_Icons-14.jpg";
import sdg15 from "assets/img/SDGs/E_SDG_Icons-15.jpg";
import sdg16 from "assets/img/SDGs/E_SDG_Icons-16.jpg";
import sdg17 from "assets/img/SDGs/E_SDG_Icons-17.jpg";

import { MEDIA_ROOT } from "config.js";

//service
import {
  getProjectDetails,
  createComment,
  createDiscussion,
  deleteProject
} from "../../services/projectDataProvider";
import {createQuiz, getQuizDetails, updateQuiz} from "../../services/quizDataProvider";
import { ProjectPermissions } from "utils/roles";
import { getData } from "services/userDataProvider.js";

import projectStyles from "./projectStyles";
import QuizExportButton from "../Quiz/QuizExportButton";
import {
  downloadCsvLatestMultipleFiles,
  downloadCsvLatestSingleFile,
  downloadExcelLatestMultipleFiles,
  downloadExcelLatestSingleFile
} from "../../utils/quiz";
import Box from "@material-ui/core/Box";

export const SDGicons = {
  'SDG1': sdg1,
  'SDG2': sdg2,
  'SDG3': sdg3,
  'SDG4': sdg4,
  'SDG5': sdg5,
  'SDG6': sdg6,
  'SDG7': sdg7,
  'SDG8': sdg8,
  'SDG9': sdg9,
  'SDG10': sdg10,
  'SDG11': sdg11,
  'SDG12': sdg12,
  'SDG13': sdg13,
  'SDG14': sdg14,
  'SDG15': sdg15,
  'SDG16': sdg16,
  'SDG17': sdg17
};

// Sample tags: To be fetched from network call
const sampleTagsList = [
  { label: "tag1", value: "tag1" },
  { label: "tag2", value: "tag2" },
  { label: "tag3", value: "tag3" }
];

const surveyOptions=[
  {
    value:'multi-user',
    text:"multi-user",
  },
  {
    value:'single-user',
    text:"single-user",
  }
]

const tabNames = [
    "about",
    "participants",
    "surveys",
    "documents",
    "discussions",
];

const tabColors = [
  "#249A46",
  "#183668",
  "#8F1838",
  "#FBC412",
  "#EB1C2D"
];

class ProjectDetails extends Component {
  constructor(props) {
    super(props);
    this.formSchema = null;
    this.currentProjectId = null;
    this.state = {
      editable: false,
      updatedData: {},
      instituteOptions: [],
      users: [],
      pageNumber: 0,
      pageSize: 9,
      type: [],
      searchTerm: "",
      pageCount: 0,
      page: 0,
      isChecked: false,
      lookFor: ["institute"],
      uploadQuizFile: false,
      quizFile: null,
      descriptionField:'',
      survey:'',
      isActive:true,
      isAcceptingSubmission:true,
      isDescriptionFieldError:false,
      isSurveyError:false,
    };
  }

  handleChangeDescriptionField=(e)=>{
    let descriptionData = this.state.newDiscussionData || {};
    descriptionData['descriptionField'] = e.target.value;
    if(this.state.isDescriptionFieldError && !!descriptionData.descriptionField){
      this.setState({ isDescriptionFieldError: false })
    }
    this.setState({ descriptionField: descriptionData.descriptionField });

  }


  handleChangeAcceptingSubmission=(event, isChecked)=>{
    this.setState(prevState=>({...prevState, isAcceptingSubmission:isChecked}))
  }

  handleChangeActive=(event, isChecked)=>{
    this.setState(prevState=>({...prevState, isActive:isChecked}))
  }

  handleDeleteSurvey = async() => {
      //const projectId=this.state.id
      if (window.confirm("Are you sure that you want to delete this survey?") === true) {
          // TODO call network
          // await deleteQuiz(this.props.axios, projectId)
      }
  };

  handleChangeSurvey=(survey)=>{

    this.setState(prevState=>({...prevState, survey}))
    if(this.state.isSurveyError){
      this.setState({ isSurveyError: false })
    }
  }

  onComment = discussionId => comment => {
    createComment(this.props.axios, discussionId, comment).then(data => {
      let projectDetails = this.state;
      let discussions = projectDetails.discussions.map(discussion => {
        if (discussion.id === data.id) {
          return data;
        }
        return discussion;
      });
      this.setState({ discussions: discussions });
    });
  };

  createNewDiscussion = (projectId, topicId) => e => {
    let discussionData = this.state.newDiscussionData;
    discussionData["project"] = projectId;
    discussionData["topic"] = topicId;

    createDiscussion(this.props.axios, discussionData).then(data => {
      let discussions = this.state.discussions || [];
      discussions.push(data);
      this.setState({ discussions: discussions, startNewDiscussion: false });
    });
  };

  discussionUpdate = fieldName => e => {
    let discussionData = this.state.newDiscussionData || {};
    discussionData[fieldName] = e.target.value;
    this.setState({ newDiscussionData: discussionData });
  };

  handleModalOpen = topicId => e => {
    e.preventDefault();
    this.setState({ startNewDiscussion: true, topicId: topicId });
  };

  handleModalClose = e => {
    e.preventDefault();
    this.setState({ startNewDiscussion: false });
  };

  onEditClicked = e => {
    let projectId =
      this.props.match.params && this.props.match.params.projectId;
    if (projectId === undefined) {
      this.props.history.push("/projects");
    }
    this.props.history.push(
      `/projects/${projectId}/edit`
    );
  };

  handleSurveyLinkClick = quizName => e => {
    e.preventDefault();
    this.props.history.push(`/quiz/${quizName}`);
  };

  handleSurveyManageClick = quizName => e => {
    e.preventDefault();
    this.props.history.push(`/quiz/manage/${quizName}`);
  };

  deactivateQuizSubmissions = (quizDetails) => {
    const projectDetails = this.state;
    quizDetails["accept_submissions"] = false;
    updateQuiz(this.props.axios, quizDetails.id, quizDetails).then(data => {
      let quizzes = projectDetails.quizzes || [];
      quizzes.forEach(qz => {
        if (qz.id === data.id) {
          qz.accept_submissions = data.accept_submissions;
        }
      });
      this.setState({quizzes: quizzes});
    });
  }

  handleSettingsUpdate = settings => {
    // console.log("settings updated", settings);
    if (settings.styling_info) {
      this.setState({stylingInfo: settings.styling_info});
    }
  };

  updateCurrentProjectId() {
    this.currentProjectId = this.props.match.params.projectId
  }

  componentDidUpdate() {
    if(this.props.match.params.projectId !== this.currentProjectId) {
      this.getProjectDetails();
      this.updateCurrentProjectId()
    }
  }

  componentDidMount = async () => {
    this.updateCurrentProjectId()
    this.getProjectDetails();
  };

  getProjectDetails = () => {
    let projectDetails = {};
    let projectId =
      this.props.match.params && this.props.match.params.projectId;
    if (projectId === undefined) {
      this.props.history.push("/projects");
    }

    getProjectDetails(this.props.axios, projectId).then(data => {
      projectDetails = data;
      projectDetails['users'] = projectDetails['participants'];
      const stylingInfo = projectDetails.stylingInfo;
      delete projectDetails.stylingInfo;
      this.setState({ ...projectDetails, stylingInfo: stylingInfo });
    });
    this.props.user &&
      getData(this.props.axios, this.props.user.user_id).then(data => {
        this.setState({ userData: data });
      });
  };

  renderTopic(projectId, topicDetails, topicDiscussions) {
    const { classes } = this.props;
    const stylingInfo = this.state.stylingInfo || {};
    return (
      <Card className={classes.projectInfoCard} key={`project-${projectId}-topic-${topicDetails.id}`} style={stylingInfo.tabContent ? stylingInfo.tabContent : null}>
        <CardHeader className={classes.topicTitleContainer} style={ stylingInfo.tabTitle ? stylingInfo.tabTitle : null }>
          <h4 style={{"font-family": "unset"}}>{topicDetails.name}</h4>
          <div style={projectStyles.projectUpdateInfo}>
            <label style={stylingInfo.labelStyle ? stylingInfo.labelStyle : null}>Last Update: </label>
            <span>{" "}</span>
            <span>
              {moment(topicDetails.updated_at).format("MMMM Do YYYY")}
            </span>
          </div>
        </CardHeader>
        <CardBody>
          <div className={classes.topicDescriptionContainer}>
            {ReactHtmlParser(topicDetails.description)}
          </div>
          {this.renderCreateDiscussion(projectId, topicDetails.id)}
        </CardBody>
        <CardFooter>{this.renderDiscussions(topicDiscussions)}</CardFooter>
      </Card>
    );
  }

  renderTopicLessDiscussions(projectId, discussions) {
    const { classes } = this.props;
    const stylingInfo = this.state.stylingInfo || {};
    return (
      <Card className={classes.projectInfoCard} key={`discussion-project-${projectId}`} style={stylingInfo.tabContent ? stylingInfo.tabContent : null}>
        <CardHeader className={classes.topicTitleContainer} style={ stylingInfo.tabTitle ? stylingInfo.tabTitle : null }>
          <h4 style={{"font-family": "unset"}}>Other Discussions</h4>
        </CardHeader>
        <CardBody>{this.renderCreateDiscussion(projectId, null)}</CardBody>
        <CardFooter>{this.renderDiscussions(discussions)}</CardFooter>
      </Card>
    );
  }

  renderAbout(projectDetails) {
    const { classes } = this.props;
    const stylingInfo = this.state.stylingInfo || {};
    const createdByName = projectDetails.created_by.first_name
      ? ` ${projectDetails.created_by.first_name} ${projectDetails.created_by.last_name} `
      : ` ${projectDetails.created_by.email} `;
    return (
      <Card className={classes.projectInfoCard} style={stylingInfo.tabContent ? stylingInfo.tabContent : null}>
        <CardBody>
          <CardHeader className={classes.topicTitleContainer} style={ stylingInfo.tabTitle ? stylingInfo.tabTitle : null}>
            <h4 style={{"fontFamily": "unset"}}>
              About
            </h4>
          </CardHeader>
          <Accordion
            stylingInfo={ stylingInfo.tabContent ? stylingInfo.tabContent : null}
            active={0}
            collapses={[
              {
                title: "Description",
                content:
                  <div className={classes.projectDescriptionContainer}>
                    {ReactHtmlParser(projectDetails.description)}
                  </div>
              },
              {
                title: "Project Type",
                content:
                  projectDetails.project_type === null ? (
                    <h5 style={{"font-family": "unset"}}>Not Available</h5>
                  ) : (
                    <h5 style={{"font-family": "unset"}}>{projectDetails.project_type}</h5>
                  )
              },
              {
                title: "SDGs",
                content: this.renderSDGs(
                  projectDetails.sustainable_development_goals
                )
              },
              {
                title: "Focus Areas",
                content: (
                  <div className={classes.itemsList}>
                    {/*{projectDetails.focus_areas.map((focus_area, idx) => (
                      <h4 key={idx} style={{"font-family": "unset"}}>{focus_area}</h4>
                    ))}*/}
                    {projectDetails.focus_areas ? (
                      projectDetails.focus_areas.map((focus_area, idx) => (
                        <h4 key={idx} style={{ fontFamily: "unset" }}>
                          {focus_area}
                        </h4>
                      ))
                    ) : (
                      <p>Focus areas not specified for this project.</p>
                    )}
                  </div>
                )
              },
              {
                title: "Regions",
                content: (
                  <div className={classes.itemsList}>
                    {projectDetails.regions.map((region, idx) => (
                      <h5 key={idx} style={{"font-family": "unset"}}>{region}</h5>
                    ))}
                  </div>
                )
              },
              {
                title: "Visibility",
                content: (
                  <div className={classes.itemsList}>
                    <h5 style={{"font-family": "unset"}}>{projectDetails.project_visibility || "private"}</h5>
                  </div>
                )
              }
            ]}
          />
          <div className={classes.createrInfo}>
            <h4 style={{"font-family": "unset"}}>
              <small style={stylingInfo.labelStyle ? stylingInfo.labelStyle : null}>
                Created by
                <a href={`/members/${projectDetails.created_by.id}`} style={stylingInfo.anchorStyle ? stylingInfo.anchorStyle : null}>{createdByName}</a>
                {`on ${moment(projectDetails.created_at).format("MMMM Do YYYY")}`}
              </small>
            </h4>
          </div>
        </CardBody>
      </Card>
    );
  }

  handleQuizModalClose = e => {
    e.preventDefault();
    this.setState({ uploadQuizFile: false });
  };

  handleQuizModalOpen = e => {
    e.preventDefault();
    this.setState({ uploadQuizFile: true });
  };

  handleCsvUpload = e => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      let quizFile = file;
      this.setState({ quizFile: quizFile });
    }
  };

  handleQuizFileSubmission = (projectId) => e => {
    //network call for file upload
    e.preventDefault();
    const {
         descriptionField,
         survey,
         isDescriptionFieldError,
         isSurveyError,
         quizFile,
         isAcceptingSubmission,
          isActive,
        }=this.state
    if(isDescriptionFieldError || isSurveyError){
      return
    }

    if(!descriptionField || !survey){
      this.setState({
        isDescriptionFieldError:!descriptionField,
        isSurveyError:!survey
      })
      return
    }
    const data={
      file:quizFile,
      description:descriptionField,
      type:survey,
      active:isActive,
      accept_submissions:isAcceptingSubmission
    }
    createQuiz(this.props.axios, projectId, data)
    .then(results => {
      if (results.success) {
        this.setState({ quizFile: null, uploadQuizFile: true, uploadQuizResult: ["Created successfully!"] });
      } else {
        this.setState({ uploadQuizFile: true, uploadQuizResult: results.data });
      }
    }).catch(error => {
      this.setState({ uploadQuizFile: true, uploadQuizResult: [error.message] });
    });
  }

  async fetchQuizDetails(activeQuiz) {
    return getQuizDetails(this.props.axios, activeQuiz)
  }

  async fetchAllQuizDetails(quizzes) {
    return Promise.all(quizzes.map((quiz) => getQuizDetails(this.props.axios, quiz.name)))
  }

  renderSurveyList(projectDetails) {
    // eslint-disable-next-line react/prop-types
    const { classes } = this.props;
    const stylingInfo = this.state.stylingInfo || {};
    // const surveysTitleColor = stylingInfo.infoTitleColor || "warning";

    if (projectDetails.quizzes == null) {
      return null;
    }

    const orderedQuizzes = projectDetails.quizzes.sort((a, b) => a.display_order - b.display_order);
    return (
      <Card className={classes.projectInfoCard} style={stylingInfo.tabContent ? stylingInfo.tabContent : null}>
        <CardHeader className={classes.topicTitleContainer} style={ stylingInfo.tabTitle ? stylingInfo.tabTitle : null }>
          <h4 style={{"font-family": "unset"}}>
            Surveys
          </h4>
        </CardHeader>
        <div className={classes.flexContainerEnd} style={stylingInfo.tabContent ? stylingInfo.tabContent : null} >
          { projectDetails.user_permissions &&
            projectDetails.user_permissions.includes(ProjectPermissions.CREATE_PROJECT_SURVEYS)
            &&
            <Box display="flex" style={{ gap: '10px' }}>
              {
                projectDetails.user_permissions &&
                projectDetails.user_permissions.includes(ProjectPermissions.EXPORT_PROJECT_SURVEY_RESULTS) &&
                <QuizExportButton
                  customHandlers={[
                    {
                      name: 'CSV(Single file)',
                      handler: async () => downloadCsvLatestSingleFile(await this.fetchAllQuizDetails(orderedQuizzes))
                    },
                    {
                      name: 'CSV(Multiple files)',
                      handler: async () => downloadCsvLatestMultipleFiles(await this.fetchAllQuizDetails(orderedQuizzes))
                    },
                    {
                      name: 'Excel(Single file)',
                      handler: async () => downloadExcelLatestSingleFile(await this.fetchAllQuizDetails(orderedQuizzes))
                    },
                    {
                      name: 'Excel(Multiple files)',
                      handler: async () => downloadExcelLatestMultipleFiles(await this.fetchAllQuizDetails(orderedQuizzes))
                    },
                  ]}
                />
              }
              <Button
                type="button"
                color={stylingInfo.secondaryActionButtonColor || "secondary"}
                onClick={this.handleQuizModalOpen}
                >
                  Create New
              </Button>
              <Modal
                style={{ margin: "30px" }}
                open={this.state.uploadQuizFile}
                onClose={this.handleQuizModalClose}
                >
              <Card
                className={classes.createDiscussion}
                style={{ backgroundColor: "white", margin: "30px", width: "auto", gap: "30px" }}>
                <CardBody className={classes.quizCreationModalElements} style={stylingInfo.tabContent ? stylingInfo.tabContent : null}>
                  <h4 className={classes.discussionTitle} style={{"font-family": "unset"}} >
                    Create new survey
                  </h4>
                  <h5 style={{"font-family": "unset"}} >
                    Upload a CSV file to crate new survey for the project
                    <span> {projectDetails.name}</span>
                  </h5>
                  <div className={classes.uploadSurveyError}>
                    {this.state.uploadQuizResult && this.state.uploadQuizResult.map(error => {
                      return (<small style={{color: 'red', fontWeight: '800'}}> {error} </small>);
                    })}
                  </div>
                  <div className={classes.formFields}>
                  <input type="file" accept=".csv" onChange={e => this.handleCsvUpload(e)} />

                    <CustomTextArea
                    error={this.state.isDescriptionFieldError}
                     label={<span>
                      Description <small>(required)</small>
                     </span>}
                     value={this.state.descriptionField}
                     onChange={this.handleChangeDescriptionField}
                    />
                    <CustomSelectClassic
                     error={this.state.isSurveyError}
                     labelText={
                      <span>
                        Survey Type
                        <small>(required)</small>
                      </span>
                    }
                      onChange={this.handleChangeSurvey}
                      items={surveyOptions}
                    />
                    <CustomSwitcher
                    label='Active'
                    labelPlacement='start'
                    checked={this.state.isActive}
                    onChange={this.handleChangeActive}
                    />
                    <CustomSwitcher
                    labelPlacement='start'
                    label='Accepting Submissions'
                    name='accpet'
                    checked={this.state.isAcceptingSubmission}
                    onChange={this.handleChangeAcceptingSubmission}
                    />
                  </div>
                  <div className={classes.flexContainerEnd} style={stylingInfo.tabContent ? stylingInfo.tabContent : null}>

                    <Button
                      type="button"
                      disabled={
                      !this.state.quizFile || this.state.isDescriptionFieldError || this.state.isSurveyError}
                      color={stylingInfo.primaryActionButtonColor || "primary"}
                      onClick={this.handleQuizFileSubmission(projectDetails.id)}
                    >
                      Submit
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Modal>
            </Box>
          }
        </div>
        <CardBody className={classes.quizListContainer} style={stylingInfo.tabContent ? stylingInfo.tabContent : null}>
          {orderedQuizzes.map(quizData => {
            let percentageCompletion = (quizData.percentage_completion || 0).toFixed(1);
            let quizFinalizedStatus = "Survey is accepting submissions"
            let quizStyling = quizData.metadata && quizData.metadata.styling_info;
            let statusBackgroundColor = "#4CAF50"
            if (quizStyling == null) {
              quizStyling = stylingInfo;
            }

            if (quizData.type === "multi-user") {
              percentageCompletion = (quizData.metadata && quizData.metadata.percentage_completion) || percentageCompletion;
            }
            let completionStatus = 'Not started';
            if (percentageCompletion > 0 && percentageCompletion < 100) {
              completionStatus = 'In progress';
            } else if (percentageCompletion >= 100) {
              completionStatus = 'Completed';
            }
            if (!quizData.accept_submissions) {
              quizFinalizedStatus = "Survey has been marked as finalized"
              statusBackgroundColor = "#F44366"
            }
            const IconSurvey=quizData.type==='single-user' ? Person : People;
            // permissions for survey actions
            const canDeActivateQuiz = projectDetails.user_permissions && (projectDetails.user_permissions.includes(ProjectPermissions.DEACTIVATE_PROJECT_SURVEYS));
            const canActivateQuiz = projectDetails.user_permissions && (projectDetails.user_permissions.includes(ProjectPermissions.ACTIVATE_PROJECT_SURVEYS));
            const canManageQuiz = projectDetails.user_permissions && (canDeActivateQuiz & canActivateQuiz & projectDetails.user_permissions.includes(ProjectPermissions.EXPORT_PROJECT_SURVEY_RESULTS));
            return (
              <Card className={classes.quizCard}  style={quizStyling.tabContent ? quizStyling.tabContent : null}>
                <CardHeader className={classes.quizCardHeader} style={quizStyling.quizHeader ? quizStyling.quizHeader : null}>
                  <div className={classes.cardHeaderWrap}>
                  {
                    quizData.type ?
                    <IconSurvey className={classes.iconSurvey}/>
                    :null
                  }
                  <h3 className={classes.quizTitle} style={quizStyling.quizHeader ? quizStyling.quizHeader : null}>
                    {quizData.name}
                  </h3>
                  </div>
                </CardHeader>
                <CardBody className={classes.quizCardBody} style={quizStyling.tabContent ? quizStyling.tabContent : null}>
                  <div className={classes.quizDetails}>
                    <p className={classes.quizDescription}>
                      {quizData.description}
                    </p>
                    <div className={classes.quizUpdateInfo}>
                      <strong className={classes.quizUpdateInfoTitle}>Created On:{" "}</strong>
                      {moment(quizData.created_at).format("MMMM Do YYYY")}
                    </div>
                    <div className={classes.quizUpdateInfo}>
                      <strong className={classes.quizUpdateInfoTitle}>Updated On:{" "}</strong>
                      {moment(quizData.updated_at).format("MMMM Do YYYY")}
                    </div>
                    {quizData.accept_submissions ? (
                      <div className={classes.quizProgressContainer}>
                        <strong className={classes.quizUpdateInfoTitle}>Level of completion:{" "}</strong>: {completionStatus}
                      </div>
                    ) : null}                    
                    <div className={classes.quizUpdateInfo}>
                      <strong className={classes.quizUpdateInfoTitle}>Status:{" "}</strong> 
                      <strong style={{backgroundColor: statusBackgroundColor, color: "white", paddingLeft: "2px", paddingRight: "2px"}}>{quizFinalizedStatus}</strong>
                    </div>
                    
                    {!quizData.accessible ? (
                      <Warning>Survey locked</Warning>
                    ) : null}
                  </div>
                </CardBody>
                <CardFooter>
                  <div className={classes.quizActionsContainer}>
                    { projectDetails.user_permissions &&
                      projectDetails.user_permissions.includes(ProjectPermissions.FILL_PROJECT_SURVEYS) &&
                      <Button
                        type="button"
                        color={stylingInfo.secondaryActionButtonColor || "success"}
                        onClick={this.handleSurveyLinkClick(quizData.name)}
                        disabled={!(quizData.accessible && (quizData.accept_submissions || canActivateQuiz)) } // button disabled if survey is finalized and user cannot activate it
                        style={{ marginLeft: "0.5rem", marginRight: "0.5rem", borderRadius: '30pt' }} // Adjust style
                      >
                        <FillIcon fontSize="48px" aria-label="delete" />
                        Fill out form
                      </Button>
                    }
                    { canManageQuiz ?
                      <Button
                        type="button"
                        color={stylingInfo.secondaryActionButtonColor || "secondary"}
                        onClick={this.handleSurveyManageClick(quizData.name)}
                        style={{ marginLeft: "0.5rem", marginRight: "0.5rem", borderRadius: '30pt' }} // Adjust style
                      >
                        <SettingsIcon fontSize="48px" aria-label="delete" />
                        Settings
                      </Button> : canDeActivateQuiz && <Button
                        type="button"
                        color="success"
                        onClick={() => this.deactivateQuizSubmissions(quizData)}
                        disabled={!quizData.accept_submissions}
                        style={{ marginLeft: "0.5rem", marginRight: "0.5rem", borderRadius: '30pt' }} // Adjust style
                      >
                        Mark as finalized
                      </Button>  
                    }
                    { projectDetails.user_permissions &&
                      projectDetails.user_permissions.includes(ProjectPermissions.DELETE_PROJECT_SURVEYS) &&
                      <Button
                        type="button"
                        color='danger'
                        onClick={this.handleDeleteSurvey}
                        disabled={!quizData.accessible}
                        style={{ marginLeft: "0.5rem", marginRight: "0.5rem", borderRadius: '30pt' }} // Adjust style
                      >
                        <DeleteIcon fontSize="48px" aria-label="delete" />
                        Delete
                      </Button>
                    }
                    { projectDetails.user_permissions &&
                      projectDetails.user_permissions.includes(ProjectPermissions.EXPORT_PROJECT_SURVEY_RESULTS) &&
                      <QuizExportButton
                        onlyLatestSubmissions
                        noExploreSubmissions
                        quizDetails={() => this.fetchQuizDetails(quizData.name)}
                      />
                    }
                  </div>
                </CardFooter>
              </Card>
            );
          })}
        </CardBody>
      </Card>
    );
  }

  renderSDGs(sdgs) {
    const { classes } = this.props;
    return (
      <CardBody>
        <div className={classes.sdg}>
          {sdgs.map(sdg => {
            return (
              <img
                className={classes.sdgImage}
                src={SDGicons[sdg]}
                key={sdg}
                alt={sdg}
              />
            );
          })}
        </div>
      </CardBody>
    );
  }

  renderDocuments(documents) {
    const { classes } = this.props;
    const stylingInfo = this.state.stylingInfo || {};
    return (
      <div className={classes.documentsContainer} style={stylingInfo.tabContent ? stylingInfo.tabContent : null}>
        <CardHeader className={classes.topicTitleContainer} style={ stylingInfo.tabTitle ? stylingInfo.tabTitle : null }>
          <h4 style={{"font-family": "unset"}}>Documents</h4>
        </CardHeader>
        <TableContainer className={classes.documentsTableContainer} style={stylingInfo.documentsTable ? stylingInfo.documentsTable : null}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader}>#</TableCell>
                <TableCell className={classes.tableHeader}>Document</TableCell>
                <TableCell className={classes.tableHeader}>Details</TableCell>
                <TableCell className={classes.tableHeader}>Last update</TableCell>
                <TableCell className={classes.tableHeader}>Download link</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {documents.map((document, idx) => {
                return (
                  <TableRow>
                    <TableCell className={classes.tableCell}>{idx + 1}</TableCell>
                    <TableCell className={classes.tableCell}>{document.name}</TableCell>
                    <TableCell className={classes.tableCell}>{document.description}</TableCell>
                    <TableCell className={classes.tableCell}>{moment(document.updated_at).format("MMMM Do YYYY")}</TableCell>
                    <TableCell className={classes.tableCell}>
                      <a key={idx} href={`${MEDIA_ROOT}${document.file}`}>download</a>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }

  renderDiscussion(discussion) {
    const { classes } = this.props;
    const stylingInfo = this.state.stylingInfo || {};
    return (
      <div
        key={`discussion-${discussion.id}`}
        className={classes.discussionDescription}
        style={stylingInfo.tabContent ? stylingInfo.tabContent : null}
      >
        <Info>{discussion.description}</Info>
        <Comment
          comments={discussion.comments || []}
          onComment={this.onComment(discussion.id)}
          user={this.state.userData}
        />
      </div>
    );
  }

  renderDiscussions(discussions) {
    const stylingInfo = this.state.stylingInfo || {};
    return (
      <Accordion
        stylingInfo={stylingInfo.tabContent ? stylingInfo.tabContent : null}
        collapses={discussions.map(discussion => {
          return {
            title: discussion.name,
            content: this.renderDiscussion(discussion)
          };
        })}
      />
    );
  }

  renderCreateDiscussion(projectId, topicId) {
    const { classes } = this.props;
    const { startNewDiscussion } = this.state;
    const stylingInfo = this.state.stylingInfo || {};
    const actionButtonColor = stylingInfo.secondaryActionButtonColor || "secondary";
    return (
      <div
        key={`project-${projectId}-topic-${topicId}`}
        className={classes.createDiscussion}
      >
        <span className={classes.createDiscussionIcon}>
          <Tooltip
            id="tooltip-top"
            title="Start New Discussion"
            placement="bottom"
            classes={{ tooltip: this.props.classes.tooltip }}
          >
            <Button
              round
              color={actionButtonColor}
              onClick={this.handleModalOpen(topicId)}
            >
              <span className="material-icons">question_answer</span>
            </Button>
          </Tooltip>
        </span>

        <Modal
          style={{ margin: "30px" }}
          open={startNewDiscussion?startNewDiscussion : false}
          onClose={this.handleModalClose}
        >
          <Card
            style={{ backgroundColor: "white", margin: "30px", width: "auto" }}
          >
            <CardBody>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12}>
                  <h4 className={classes.discussionTitle}>
                    Start A New Discussion
                  </h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={10}>
                  <CustomInput
                    labelText={
                      <span>
                        Discussion Title <small>(required)</small>
                      </span>
                    }
                    id="discussiontitle"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: this.discussionUpdate("name"),
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className={classes.inputAdornment}
                        >
                          <ContactMail className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      )
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={10}>
                  <CustomInput
                    labelText={
                      <span>
                        Description <small>(required)</small>
                      </span>
                    }
                    id="description"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: this.discussionUpdate("description"),
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className={classes.inputAdornment}
                        >
                          <Subject className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      )
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={10}>
                  <div className={classes.select}>
                    <Select
                      isMulti
                      theme={theme => ({
                        ...theme,
                        borderRadius: 5,
                        colors: {
                          ...theme.colors,
                          primary25: "grey",
                          primary: "rgb(243, 197, 63)"
                        }
                      })}
                      placeholder={<span>Tags</span>}
                      options={sampleTagsList}
                      className={classes.filter}
                      // onChange={this.discussionUpdate("tags")}
                    />
                  </div>
                </GridItem>
                <GridItem
                  style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}
                  xs={6}
                  sm={6}
                  md={6}
                >
                  <Button
                    type="button"
                    color="success"
                    onClick={this.createNewDiscussion(
                      projectId,
                      this.state.topicId
                    )}
                  >
                    Create
                  </Button>
                  <Button
                    type="button"
                    color="grey"
                    onClick={this.handleModalClose}
                  >
                    Cancel
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </Modal>
      </div>
    );
  }

  renderEditButton() {
    const projectDetails = this.state;
    const stylingInfo = this.state.stylingInfo || {};
    const actionButtonColor = stylingInfo.primaryActionButtonColor || "primary";
    return (
      projectDetails.user_permissions &&
      projectDetails.user_permissions.includes(ProjectPermissions.UPDATE_PROJECT) &&
      <FormControlLabel
        control={
          <Switch
            checked={this.state.editable}
            onChange={this.onEditClicked}
            value="editable"
            color={actionButtonColor}
          />
        }
        label="Turn on edit mode"
      />
    );
  }

  handleDelete = (projectId) => {
    if (window.confirm("Are you sure you want to delete the project?") === true) {
      deleteProject(this.props.axios, projectId).then(data => {
          this.goBack();
        }
      );
    }
  };

  goBack() {
    this.props.history.go(-1);
  }

  renderDeleteButton() {
    const projectDetails = this.state;
    const stylingInfo = this.state.stylingInfo || {};
    const actionButtonColor = stylingInfo.secondaryActionButtonColor || "secondary";
    return (
      projectDetails.user_permissions &&
      projectDetails.user_permissions.includes(ProjectPermissions.DELETE_PROJECT) &&
      <span>
          <Tooltip
            id="tooltip-top"
            title="Delete Project"
            placement="bottom"
            classes={{ tooltip: this.props.classes.tooltip }}>
            <Button
                color={actionButtonColor}
                onClick={() => this.handleDelete(projectDetails.id)}
              >
                <DeleteIcon fontSize="48px" aria-label="delete" />
                  Delete Project
              </Button>
          </Tooltip>
        </span>
    );
  }

  renderEditStylesButton() {
    const projectDetails = this.state;
    const stylingInfo = this.state.stylingInfo || {};
    const actionButtonColor = stylingInfo.primaryActionButtonColor || "primary";

    return (
      projectDetails.user_permissions &&
      projectDetails.user_permissions.includes(ProjectPermissions.UPDATE_PROJECT_STYLES) &&
      <FormControlLabel
        control={
          <Switch
            checked={this.state.showSettings === true}
            onChange={e => this.setState({showSettings: !this.state.showSettings})}
            value="editable"
            color={actionButtonColor}
          />
        }
        label="Change project settings"
      />
    );
  }

  renderProjectDetails() {
    const { classes } = this.props;
    const projectDetails = this.state;
    const stylingInfo = this.state.stylingInfo || {};
    const videoBannerLink = projectDetails && projectDetails.metadata && projectDetails.metadata["video_banner_link"];
    //const projectAvatar = projectDetails && projectDetails.avatar;
    const projectBanner = projectDetails && projectDetails.banner;
    if (projectDetails.name === undefined) {
      return null;
    }

    let activeTab =
      this.props.match.params && this.props.match.params.activeTab;
    let activeTabIdx = tabNames.indexOf(activeTab);
    if (activeTabIdx < 0) {
      activeTabIdx = 0;
    }
    return (
      <GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          key={`project-${this.props.projectId}`}
          style={stylingInfo.projectPage ? stylingInfo.projectPage : null}
        >
          <Card chart className={classes.cardHover} style={stylingInfo.projectPage ? stylingInfo.projectPage : null}>
            <CardHeader className={classes.cardHeaderHover}>
              <CardAvatar project className={classes.imgcontainer} style={stylingInfo.projectAvatar}>
                {videoBannerLink ? (
                  <video id="project-video-banner" controls playsinline autoPlay={true} muted loop type="video/mp4" className={classes.videoContent}>
                    <source src={videoBannerLink}></source>
                    Your browser does not support the video.
                  </video>
                ) : (
                  projectBanner && <a href="#avatar" onClick={e => e.preventDefault()}>
                    <img
                      className={classes.imgContent}
                      src={projectBanner && projectBanner.includes("http") ? projectBanner : `${MEDIA_ROOT}${projectBanner}`}
                      alt="..." />
                  </a>
                )}
              </CardAvatar>
              <div className={classes.projectNameInfoContainer}>
                <div className={classes.projectNameInfo}>
                  <h2 style={ stylingInfo.projectName ? stylingInfo.projectName : projectStyles.cardTitleBlack}>
                    {projectDetails.name}
                  </h2>
                  <div className={classes.projectUpdateInfo} style={stylingInfo.projectUpdateInfo}>
                    <label style={stylingInfo.labelStyle ? stylingInfo.labelStyle : null}>Last Update: </label>
                    <span>{" "}</span>
                    <span>
                      {moment(projectDetails.updated_at).format("MMMM Do YYYY")}
                    </span>
                  </div>
                </div>
                <div className={classes.actionsContainer}>
                  {this.renderEditButton()}
                  {this.renderEditStylesButton()}
                  {this.renderDeleteButton()}
                </div>
              </div>
            </CardHeader>
            <CardBody style={stylingInfo.projectPage ? stylingInfo.projectPage : null}>
              <br />
              <NavPills
                active={activeTabIdx}
                tabColors={tabColors}
                color={stylingInfo.navTabsColor ? stylingInfo.navTabsColor : "info"}
                vertical={{
                  tabsGrid: { xs: 12, sm: 12, md: 12 },
                  contentGrid: { xs: 12, sm: 12, md: 12 }
                }}
                tabs={this.getTabList(projectDetails)}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  getTabList(projectDetails) {
    const { classes } = this.props;
    const stylingInfo = this.state.stylingInfo || {};
    let tabs = [
      {
        tabButton: projectDetails.project_type ? `About ${projectDetails.project_type}` : "About",
        tabContent: this.renderAbout(projectDetails)
      },
      {
        tabButton: "Participants",
        tabContent: (
          <div style={stylingInfo.tabContent ? stylingInfo.tabContent : null}>
            <CardHeader className={classes.topicTitleContainer} style={ stylingInfo.tabTitle ? stylingInfo.tabTitle : null }>
              <h4 style={{"font-family": "unset"}}>Participants</h4>
            </CardHeader>
            <ParticipantsList
              stylingInfo={stylingInfo.participantsTable ? stylingInfo.participantsTable : null}
              participants={this.state.participants}
              projectId={this.state.id}
              axios={this.props.axios}
              user={this.props.user}
            />
          </div>
        )
      }
    ]
    tabs.push({
    tabButton: "Surveys",
    tabContent: this.renderSurveyList(projectDetails)
    });
    tabs.push({
      tabButton: "Documents",
      tabContent: this.renderDocuments(projectDetails.documents)
    })
    tabs.push({
      tabButton: "Discussions",
      tabContent: this.renderTopicLessDiscussions(
        projectDetails.id,
        projectDetails.discussions.filter(
          discussion => discussion.topic === null
        )
      )
    })
    tabs.push(...projectDetails.topics.map(topicDetails => {
        return {
          tabButton: topicDetails.name,
          tabContent: this.renderTopic(
            projectDetails.id,
            topicDetails,
            projectDetails.discussions.filter(
              discussion => discussion.topic === topicDetails.id
            )
          )
        };
      })
    )
    return tabs;
  }

  render() {
    let projectDetails = this.state;

    return (
      <div>
        {this.renderProjectDetails()}
        {
          projectDetails.user_permissions &&
          projectDetails.user_permissions.includes(ProjectPermissions.UPDATE_PROJECT_STYLES) &&
          this.state.showSettings &&
          <ProjectSettings
            axios={this.props.axios}
            projectId={projectDetails.id}
            settings={projectDetails.metadata}
            onUpdate={this.handleSettingsUpdate}
            onClose={e => this.setState({showSettings: false})}
          />
        }
      </div>
    );
  }
}

export default withStyles(projectStyles)(ProjectDetails);
