import { API_ROOT } from "config.js";
import axios from "axios";

const USER_DETAILS_ENDPOINT = `${API_ROOT}/api/users/`;
const NOTIFICATIONS_ENDPOINT = `${API_ROOT}/api/notifications/`;
const USER_CONTACTS_ENDPOINT = `${API_ROOT}/api/chat/contacts/`;

export async function getMember(user_id) {
  let url = `${USER_DETAILS_ENDPOINT}${user_id}/`;
  return (await axios.get(url)).data;
}

export async function getData(axios, user_id) {
    let url = `${USER_DETAILS_ENDPOINT}`;
  if (user_id) {
    url = `${USER_DETAILS_ENDPOINT}${user_id}/`;
  }
    const response = await axios.get(url);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function updateUserData(user_id, token, userData) {
  let payload = userData;
  const response = await axios.post(`${USER_DETAILS_ENDPOINT}${user_id}/`,
    payload,
    {
      headers: {
        Authorization: `Token ${token}`
      }
    });
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function updateData(axios, user_id, userData) {
  let payload = userData;
  if (userData.avatarFile) {
    payload = new FormData();
    payload.append("file", userData.avatarFile);
    delete userData["avatarFile"];

    const json = JSON.stringify(userData);
    const blob = new Blob([json], {
      type: "application/json"
    });
    payload.append("document", blob);
  }
  const response = await axios.post(
    `${USER_DETAILS_ENDPOINT}${user_id}/`,
    payload
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function updateRoleData(axios, userData) {
  let payload = userData;
  const response = await axios.post(`${USER_DETAILS_ENDPOINT}roles/`, payload);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function completeRegistration(axios, user_id) {
  let url = `${USER_DETAILS_ENDPOINT}${user_id}/completeregistration/`;
  const response = await axios.get(url);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function searchUsers(axios, searchData) {
  const response = await axios.post(
    `${USER_DETAILS_ENDPOINT}search/`,
    searchData
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function getContacts(axios) {
  let url = `${USER_CONTACTS_ENDPOINT}`;
  const response = await axios.get(url);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function connectionRequest(axios, requestData) {
  let url = `${USER_CONTACTS_ENDPOINT}`;
  const response = await axios.post(url, requestData);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}
export async function removeConnection(axios, requestData) {
  let url = `${USER_CONTACTS_ENDPOINT}${requestData}`;
  const response = await axios.delete(url);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function updateRequest(axios, requestData) {
  let url = `${USER_CONTACTS_ENDPOINT}${requestData.id}/`;
  const response = await axios.patch(url, { accepted: requestData.accepted });
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function getNotifications(token) {
  const response = await axios({
    method: "get",
    headers: {
      Authorization: `Token ${token}`
    },
    url: NOTIFICATIONS_ENDPOINT
  });
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

// export async function getNotifications(axios) {
//   const response = await axios.get(`${NOTIFICATIONS_ENDPOINT}`);
//   if (response.status < 200 || response.status >= 300) {
//     throw new Error(response.statusText);
//   }
//   const data = response.data;
//   return data;
// }

export async function readNotification(axios, notification_id) {
  let payload = {
    unread: false
  };
  const response = await axios.post(
    `${NOTIFICATIONS_ENDPOINT}${notification_id}/`,
    payload
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}
