import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Public from "@material-ui/icons/Public";
import Star from "@material-ui/icons/Star";
import StarBorder from "@material-ui/icons/StarBorder";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  tableContainer: {
    overflow: "auto",
  },
  table: {
    minWidth: 650,
  },
  image: {
    width: "50px",
    height: "50px",
    borderRadius: "8px",
    objectFit: "contain",
  },
});

const ActionMenu = ({
  id,
  history,
  websiteUrl,
  unfollowInstitute,
  followInstitute,
  idx,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isFollow, setIsFollowing] = useState(true);
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleView = (id) => {
    history.push(`/institutes/${id}`);
    handleClose();
  };


  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={() => handleView(id)}>View Institute</MenuItem>
        <MenuItem onClick={() => window.open(websiteUrl, "_new")}>
          {" "}
          <Public className={classes.underChartIcons} />
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (isFollow) {
              unfollowInstitute(idx, id)();
              setIsFollowing(false);
              handleClose()
              return;
            }

            followInstitute(idx, id)();
            setIsFollowing(true);
            handleClose()
          }}
        >
          {isFollow ? (
            <Star className={classes.underChartIcons} />
          ) : (
            <StarBorder className={classes.underChartIcons} />
          )}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ActionMenu;
