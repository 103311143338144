import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { MEDIA_ROOT } from "config.js";
import UserImg from "../../assets/img/default-avatar.png";
import ActionMenu from "./ActionMenu";

const useStyles = makeStyles({
  tableContainer: {
    overflow: "auto",
  },
  table: {
    minWidth: 650,
  },
  image: {
    width: "50px",
    height: "50px",
    borderRadius: "8px",
    objectFit: "contain",
  },
  button: {
    all: "unset",
  },
});

const UserSummaryTable = ({ data, history }) => {
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderByColumn, setOrderByColumn] = useState("first_name");
  const sortedData = [...data].sort((a, b) => {
    if (order === "asc") {
      return a[orderByColumn] > b[orderByColumn] ? 1 : -1;
    } else {
      return a[orderByColumn] < b[orderByColumn] ? 1 : -1;
    }
  });

  const handleSortRequest = (column) => {
    setOrder(
      column === orderByColumn ? (order === "asc" ? "desc" : "asc") : "asc",
    );
    setOrderByColumn(column);
  };

  const handleView = (userData) => {
    history.push({
      pathname: `/member-profile/${userData.id}`,
      state: { member: userData },
    });
  };

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Avatar</TableCell>
            <TableCell align="left">
              <TableSortLabel
                active={orderByColumn === "first_name"}
                direction={orderByColumn === "first_name" ? order : "asc"}
                onClick={() => handleSortRequest("first_name")}
              >
                First name
              </TableSortLabel>
            </TableCell>
            <TableCell align="left">
              <TableSortLabel
                active={orderByColumn === "last_name"}
                direction={orderByColumn === "last_name" ? order : "asc"}
                onClick={() => handleSortRequest("last_name")}
              >
                Last name
              </TableSortLabel>
            </TableCell>

            <TableCell align="left">Adress</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.length ? (
            sortedData.map((userData) => {
              const { first_name, last_name, avatar, id, city } = userData;
              const imgUrl = avatar ? `${MEDIA_ROOT}${avatar}` : UserImg;
              const preparedCity = city ? city : "-";
              return (
                <TableRow key={id}>
                  <TableCell align="left" component="th" scope="row">
                    <button
                      className={classes.button}
                      onClick={() => handleView(userData)}
                    >
                      <img
                        className={classes.image}
                        src={imgUrl}
                        alt={first_name}
                      />
                    </button>
                  </TableCell>
                  <TableCell align="left">{first_name}</TableCell>
                  <TableCell align="left">{last_name}</TableCell>
                  <TableCell align="left">{preparedCity}</TableCell>
                  <TableCell align="right">
                    <ActionMenu userData={userData} history={history} />
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <h6>No Member Data</h6>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserSummaryTable;
