import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
//import {connectionRequest} from 'services/userDataProvider'
const ActionMenu = ({
    userData, history
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
 
  const open = Boolean(anchorEl);
  const isConnected=false

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleView = (userData) => {
    history.push({
    pathname: `/member-profile/${userData.id}`,
     state: { member: userData },
    });
    handleClose();
  };

//TODO:similar to connect/ message can be usable
 // function handleActionSelection (participant) {
//   if (participant["message"] === true)  {
//
//     window.open("/chat", "_self")
//   } else  {
//     if (participant.id === this.props.user.user_id) {
//       let message = "You cannot send request to yourself";
//       window.alert(message);
//       return false;
//     }
//     else {
//       connectionRequest(this.props.axios, { targetUserId: participant["user"]["id"] }).then(data => {
//         let message = "Connection request sent";
//         window.alert(message);
//       });
//     }
//   }
// }


  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={() => handleView(userData.id)}>View Profile</MenuItem>
        {isConnected 
        ? <MenuItem onClick={() => handleView()}>Message</MenuItem>
        : <MenuItem onClick={() => handleView()}>Connect</MenuItem> 
        }
      </Menu>
    </div>
  );
};

export default ActionMenu;
