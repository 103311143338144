/* eslint-disable react/prop-types */
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Hidden from "@material-ui/core/Hidden";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import Menu from "@material-ui/icons/Menu";

// core components
import HeaderLinks from "./HeaderLinks.jsx";
import Button from "components/CustomButtons/Button.jsx";
import SearchComponent from "components/SearchComponent/SearchComponent.jsx";
import headerStyle from "assets/jss/material-dashboard-react/components/headerStyle.jsx";
import "./searchStyle.css";
//import logo from "../../assets/img/HEDERA-blatt.png";
import { buttonColorScheme } from "../../../src/assets/jss/material-dashboard-react/layouts/appstyleconfigs.jsx";

function Header(props) {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [filterState, setFilterState] = React.useState("");
  // function makeBrand() {
  //   var name;
  //   props.routes.map((prop, key) => {
  //     if (prop.path === props.location.pathname) {
  //       name = prop.navbarName;
  //     }
  //     return null;
  //   });
  //   return name;
  // }
  const { classes, color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color,
  });

  const handleChange = (e) => {
    let value = e.target.value;
    setSearchTerm(value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setValue();
    }
  };

  const setValue = () => {
    if (searchTerm) {
      props.onSearchClick && props.onSearchClick(searchTerm);
      if (props.location.pathname !== "/dashboard") {
        props.history.push("/dashboard");
      }
    }
  };

  return (
    <AppBar
      className={classes.appBar + appBarClasses}
      style={{ position: "sticky" }}
    >
      <Toolbar className={classes.toolbarContainer}>
        <div className={classes.toolbarItem}>
          <Hidden
            mdUp
            implementation="css"
            className={classes.menuIconContainer}
          >
            <div style={{ display: "flex" }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={props.handleDrawerToggle}
              >
                <Menu />
              </IconButton>
            </div>
          </Hidden>

          <div className={classes.simpleSearchContainer}>
            <input
              type="search"
              placeholder="Search"
              onChange={(e) => handleChange(e)}
              onKeyDown={(e) => handleKeyDown(e)}
              value={searchTerm}
            />
            <Tooltip
              id="tooltip-top"
              title="Advanced search"
              placement="bottom"
              classes={{ tooltip: classes.tooltip }}
            >
              <IconButton
                aria-label="Advanced Search"
                onClick={() => setFilterState(!filterState)}
              >
                <span
                  style={{
                    color: filterState === true ? "blue" : "gray",
                    fontSize: "30px",
                  }}
                  className="material-icons"
                >
                  manage_search
                </span>
              </IconButton>
            </Tooltip>
          </div>

          <Hidden smDown implementation="css">
            <HeaderLinks history={props.history} />
          </Hidden>
        </div>

        <div className={classes.toolbarItem}>
          <div className={classes.backButtonContainer}>
            {(props.backButton && props.backButton()) ||
            props.isProjectDashboardOpen ? (
              <Button
                color={buttonColorScheme.infoButton}
                size="sm"
                onClick={() => {
                  if (props.isProjectDashboardOpen) {
                    props.isProjectDashboard(false);
                  } else {
                    props.history.goBack();
                  }
                }}
              >
                <ArrowBackIcon aria-label="back" />
                Back
              </Button>
            ) : null}
          </div>
          <div className={classes.advancedSearchContainer}>
            {filterState === true ? (
              <SearchComponent
                filterValues={props.filterValues}
                filterChange={(i) => props.filterValue && props.filterValue(i)}
                checkChange={(i) => props.checkValues && props.checkValues(i)}
              />
            ) : null}
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  onSearchClick: PropTypes.func,
  filterValue: PropTypes.func,
  checkValues: PropTypes.func,
};

export default withStyles(headerStyle)(Header);
