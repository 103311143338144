import React from "react";
import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Tabs from "@material-ui/core/Tabs";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";

// core components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
 

import InstituteSummary from "components/InstituteSummary/InstituteSummary.jsx";
import InstituteSummaryTable from "components/InstituteSummaryTable/InstituteSummaryTable.jsx";
import UserSummaryTable from "components/UserSummaryTable/UserSummaryTable.jsx";
import UserSummary from "components/UserSummary/UserSummary.jsx";
import Paginate from "components/Pagination/CustomPagination.js";
import { followInstitute } from "services/instituteDataProvider.js";
import { searchApi } from "services/searchProvider.js";
import { cardTitle } from "../../assets/jss/material-dashboard-pro-react";
import CustomSwitcher from "components/CustomSwitcher/CustomSwitcher";
import { searchOptions } from "utils/choices.jsx";
import TabPanel from "components/TabPanel/TabPanel";
import {regionShortCodeMap} from 'utils/choices.jsx'

const dashboardStyle = {
  cardTitle,
  filter: {
    marginBottom: "10px",
  },
  mapBox: {
    width: "auto",
    height: "400px",
  },
  message: {
    color: "#6f6262a3",
    width: "100%",
    height: "20vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  searchResultsContainer: {
    display: "flex",
    flexDirection: "column",
    margin: 0,
  },
  searchResultsHeader: {
    backgroundColor: "#74c186",
    display: "flex",
    gap: "20px",
    alignItems: "center",
  },
  searchResults: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "baseline",
    padding: "10px 2px 2px 2px",
    minHeight: "calc(100vh - 316px)",
  },
  searchResultItem: {
    "-webkit-flex": 1,
    "-ms-flex": 1,
    flex: 1,
  },
  searchResultsFooter: {
    display: "flex",
    justifyContent: "flex-end",
  },
  appBar: {
    backgroundColor: "#1E8449",
  },
  fullWidth: {
    width: "100%",
  },
  appBarWrap: {
    width: "100%",
    marginTop: "-10px",
  },
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

class Home extends React.Component {
  state = {
    results: [],
    filteredData: [],
    pageNumber: 0,
    pageSize: 9,
    lookFor: searchOptions.map((sp) => sp.value),
    type: [],
    searchTerm: "",
    pageCount: 0,
    checked: false,
    filterTerm: "",
    checkIncludes: [],
    isTableView: false,
    tabValue: 0,
  };

  componentDidMount = () => {
    if (this.props.searchVal) {
      this.setState({ searchTerm: this.props.searchVal }, () =>
        this.searchCall(),
      );
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.searchVal !== prevState.searchTerm) {
      return { searchTerm: nextProps.searchVal };
    }
    if (nextProps.filterVal !== prevState.filterTerm) {
      return { filterTerm: nextProps.filterVal };
    }
    if (nextProps.checkVal !== prevState.lookFor) {
      return { lookFor: nextProps.checkVal };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchVal !== this.props.searchVal) {
      this.setState({ searchTerm: prevProps.searchVal }, () =>
        this.searchCall(),
      );
    }
    if (prevProps.filterVal !== this.props.filterVal) {
      this.setState({ filterTerm: prevProps.filterVal }, () =>
        this.handleFilterSelectionChange(
          this.props.filterVal.type,
          this.props.filterVal.val,
        ),
      );
    }
    if (prevProps.checkVal !== this.props.checkVal) {
      this.setState({ lookFor: prevProps.checkVal, pageNumber: 0 }, () => {
        this.searchCall({ lookFor: this.props.checkVal });
      });
    }
  }

  componentWillUnmount = () => {};

  switchChange = () => {
    this.setState({ checked: !this.state.checked });
  };
  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  handleChangeActive = (event, isChecked) => {
    this.setState({ isTableView: isChecked });
  };

  getPayload = () => {
    const {
      lookFor,
      pageNumber,
      pageSize,
      type,
      searchTerm: name,
    } = this.state;
    let payload = {
      name,
      type,
      lookFor,
      pageNumber,
      pageSize,
    };
    return payload;
  };

  searchCall = (updatedFilter = {}) => {
    let payload = this.getPayload();
    payload.name = payload.name === undefined ? "" : payload.name;
    const preparedPayload = { ...payload, ...updatedFilter };

    if (!this.props.checkVal.length) {
      preparedPayload.lookFor = searchOptions.map((sp) => sp.value);
    }

    searchApi(this.props.axios, preparedPayload).then((data) => {
      let searchResult = data.results;
      let resultCount = parseInt(data.total);

      let results = [];
      results = results.concat(
        searchResult.projects.map((x) => {
          return { resultType: "project", ...x };
        }),
      );
      results = results.concat(
        searchResult.members.map((x) => {
          return { resultType: "member", ...x };
        }),
      );
      results = results.concat(
        searchResult.institutes.map((x) => {
          return { resultType: "institute", ...x };
        }),
      );
      results = results.concat(
        searchResult.events.map((x) => {
          return { resultType: "event", ...x };
        }),
      );
    
      let filteredData=results
      if(this.props.checkVal.length){
        filteredData= this.filterSDGs(this.state.filtersdg || [], results);
        filteredData= this.filterRegions(this.state.filterregion || [], filteredData);  
      }
     
      this.setState({
        results: results,
        filteredData: filteredData,
        searchDataAvailable: true,
        pageCount: resultCount,
      });
    });
  };

  handleChange = (e) => {
    let val = e.target.value;
    this.setState({ searchTerm: val }, () => {
      this.searchCall();
    });
  };

  handleFilterSelectionChange = (type, values, action) => {
    if (this.state.searchDataAvailable !== true) {
      this.searchCall();
    }
    if(!this.props.checkVal.length){
      return
    }
    let filteredData = this.state.results;
    let filterVals = values || [];
    filterVals = filterVals.map((dt) => dt.value);
    if (type === "instituteType") {
      this.setState({ type: filterVals, pageNumber: 0 }, () => {
        this.searchCall({ type: filterVals });
      });
      return;
    }
    this.setState({ [`filter${type}`]: filterVals }, () => {
      filteredData = this.filterInstituteType(
        this.state.filterinstituteType || [],
        filteredData,
      );

    
      filteredData = this.filterSDGs(this.state.filtersdg || [], this.state.results);
   
      filteredData = this.filterRegions(
        this.state.filterregion || [],
        filteredData,
      );
    

      this.setState({ filteredData: filteredData });
    });
  };

  filterInstituteType(filterValues, source) {
    if (filterValues.length > 0) {
      let filteredResults = this.state.results.filter((instituteData) => {
        const target = instituteData.type;
        return filterValues.filter((dt) => dt === target).length > 0;
      });
     
      return filteredResults;
    }
    return source;
  }

  filterSDGs(filterValues, initialValues) {

    if (filterValues.length > 0 && ['institute'].includes(initialValues[0].resultType) ) {
      let filteredResults = initialValues.filter(data =>
        data.sustainable_development_goals.some(value =>
          filterValues.includes(value)
        )
      );

      return filteredResults; 
    }
  
    return initialValues;
  }

  filterRegions(filterValues, source) {
  
    if (filterValues.length > 0 && ['institute'].includes(source[0].resultType) ) {
      const shortCodes = filterValues.map(value => regionShortCodeMap[value]);
  
      let filteredResults = source.filter(data =>
        shortCodes.includes(data.address.region_name) || filterValues.includes(data.address.region_name)
      );
  
      return filteredResults;
    }
 
    return source;
  }

  followInstitute = (idx, instituteId) => (e) => {
    followInstitute(this.props.axios, instituteId, true).then((data) => {
      // console.log("followInstitute", data);
      let institutes = this.state.results;
      institutes[idx].is_following = true;
      this.setState({ results: institutes });
    });
  };

  unfollowInstitute = (idx, instituteId) => (e) => {
    followInstitute(this.props.axios, instituteId, false).then((data) => {
      // console.log("unfollowInstitute", data);
      let institutes = this.state.results;
      institutes[idx].is_following = false;
      this.setState({ results: institutes });
    });
  };

  isValidSearch() {
    let payload = this.getPayload();
    if (
      (payload.name !== undefined && payload.name.length > 0) ||
      payload.type.length > 0
    )
      return true;
    return false;
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { classes, checkVal } = this.props;
    const { filteredData, tabValue, isTableView } = this.state;
    if (!this.isValidSearch()) {
      return null;
    }
   

    const instituteData = filteredData.filter(
      (data) => data.resultType === "institute",
    );
    const memberData = filteredData.filter(
      (data) => data.resultType === "member",
    );
    // Use in future for project table/card
    // const projectData = filteredData.filter(
    //   (data) => data.resultType === "project",
    // );

   

    return (
      <>
        <CustomSwitcher
          label="Table View"
          labelPlacement="start"
          checked={this.state.isActive}
          onChange={this.handleChangeActive}
        />
        <Card>
          <CardBody className={classes.searchResults}>
            {filteredData && filteredData.length ? (
              <div className={classes.appBarWrap}>
                {!checkVal.length ? (
                  <div className={classes.fullWidth}>
                    <AppBar className={classes.appBar} position="static">
                      <Tabs
                        value={tabValue}
                        indicatorColor="primary"
                        onChange={this.handleTabChange}
                        aria-label="simple tabs example"
                      >
                        <Tab label="Institute" {...a11yProps(0)} />
                        <Tab label="Projects" {...a11yProps(1)} />
                        <Tab label="Member" {...a11yProps(2)} />
                      </Tabs>
                    </AppBar>

                    <TabPanel value={tabValue} index={0}>
                      {isTableView ? (
                        <InstituteSummaryTable
                          data={instituteData}
                          history={this.props.history}
                          followInstitute={this.followInstitute}
                          unfollowInstitute={this.unfollowInstitute}
                        />
                      ) : (
                        <CardBody className={classes.searchResults}>
                          {instituteData.length ? (
                            instituteData.map((resData, idx) => (
                              <InstituteSummary
                                className={classes.searchResultItem}
                                key={`institute-${idx}`}
                                instituteData={resData}
                                followInstitute={this.followInstitute(
                                  idx,
                                  resData.id,
                                )}
                                unfollowInstitute={this.unfollowInstitute(
                                  idx,
                                  resData.id,
                                )}
                              />
                            ))
                          ) : (
                            <div className={classes.message}>
                              <h3>No institute Found</h3>
                            </div>
                          )}
                        </CardBody>
                      )}
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                      Item Two
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                      {isTableView ? (
                        <UserSummaryTable
                          data={memberData}
                          history={this.props.history}
                        />
                      ) : memberData.length ? (
                        memberData.map((userData, idx) => (
                          <UserSummary
                            key={`user-${idx}`}
                            userData={userData}
                            selfUser={this.props.user}
                          />
                        ))
                      ) : (
                        <div className={classes.message}>
                          <h3>No member Found</h3>
                        </div>
                      )}
                    </TabPanel>
                  </div>
                ) : (
                  <>
                    {checkVal.includes("institute") && (
                      <>
                        {isTableView ? (
                          <InstituteSummaryTable
                            data={instituteData}
                            history={this.props.history}
                            followInstitute={this.followInstitute}
                            unfollowInstitute={this.unfollowInstitute}
                          />
                        ) : (
                          <CardBody className={classes.searchResults}>
                            {instituteData.map((resData, idx) => (
                              <InstituteSummary
                                className={classes.searchResultItem}
                                key={`institute-${idx}`}
                                instituteData={resData}
                                followInstitute={this.followInstitute(
                                  idx,
                                  resData.id,
                                )}
                                unfollowInstitute={this.unfollowInstitute(
                                  idx,
                                  resData.id,
                                )}
                              />
                            ))}
                          </CardBody>
                        )}
                      </>
                    )}
                    {checkVal.includes("projects") && <></>}
                    {checkVal.includes("members") && (
                      <>
                        {isTableView ? (
                          <UserSummaryTable
                            data={memberData}
                            history={this.props.history}
                          />
                        ) : (
                          memberData.map((userData, idx) => (
                            <UserSummary
                              key={`user-${idx}`}
                              userData={userData}
                              selfUser={this.props.user}
                            />
                          ))
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            ) : (
              <div className={classes.message}>
                <h3>*No result Found</h3>
              </div>
            )}
          </CardBody>

          <CardFooter className={classes.searchResultsFooter}>
            <Paginate
              getPage={(page) =>
                this.setState({ pageNumber: page - 1 }, () => this.searchCall())
              }
              pageCount={this.state.pageCount}
            />
          </CardFooter>
        </Card>
      </>
    );
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired,
  axios: PropTypes.object.isRequired,
};

export default withStyles(dashboardStyle)(Home);
